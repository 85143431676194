import axios from "../../utils/axios";
import { AUTH_BASE_URL } from "../../constants/URL";

export const getAuthCode = async (code) => {
  return axios.get(`${AUTH_BASE_URL}/docusign/auth_code?code=${code}`);
};

export const getCurrentMember = async (token) => {
  return axios.get(`${AUTH_BASE_URL}/docusign/get_docusign_todo?token=${token}`);
};

export const getConsentURL = async () => {
  return axios.get(`${AUTH_BASE_URL}/docusign/get_consent_url`);
};

export const getDocuSignToken = async () => {
  return axios.get(`${AUTH_BASE_URL}/docusign/check_docusign_token`);
};