import React, { useState, useEffect } from "react";
import { Modal, Spinner, Alert } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import AuditLog from "../audit/auditlog";
import { selectAudit } from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import * as SkillQuarterService from "../../service/skillquaterly";
import * as SkillService from "../../service/skill";
import Select from "react-select";

const validationSchema = Yup.object({
  skill_level: Yup.string().required("*Actual Level is required"),
  previous_level: Yup.string().required("*Current Level is required"),
  required_level: Yup.string().required("*Planned Level is required"),
  quarter: Yup.string().required("*Quarter is required"),
});

const AddSkillQuaterly = (props) => {
  let condition = "false";

  const nameForm = "Skill Quaterly";
  const auditValueLog = useSelector(selectAudit);
  const dispatch = useDispatch();
  const { edit, showForm, onClose, editSkills, onAddCallBack } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isSkillExist, setIsSkillExist] = useState(false);
  const [allSkillData, setAllSkillData] = useState([]);
  const [allEmployee, setAllEmployee] = useState([]);
  const [dataSkill, setDataSkill] = useState([]);
  const [dataEmp, setDataEmp] = useState([]);
  const [skillError, setSkillError] = useState(false);
  const [employeeError, setEmployeeError] = useState(false);
  const [isSkillExistMsg, setIsSkillExistMsg] = useState("");
  const [locationData, setLocationData] = useState([]);
  const [dataLocation, setDataLocation] = useState([]);

  const [skillData, setSkillData] = useState({
    skill_id: "",
    employee_id: "",
    skill_level: "",
    previous_level: "",
    required_level: "",
    quarter: "",
    status: "Planned",
    year: ""
  });

  const saveSkills = (values) => {
    let skil = dataSkill?.value;
    let emp = dataEmp?.value;
    values.skill_id = skil;
    values.employee_id = emp;
    if (dataLocation.value) {
      values.location_id = dataLocation.value
    }
    setIsLoading(true);
    SkillQuarterService.saveSkillsQuarter(values)
      .then((response) => {
        condition = "false";

        if (response.data.status === true && response.data.code === 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const updateSkills = (values) => {
    let skil = dataSkill?.value;
    let emp = dataEmp?.value;
    values.skill_id = skil;
    values.employee_id = emp;
    setIsLoading(true);
    SkillQuarterService.updateSkillsQuarter(values, values.id)
      .then((response) => {
        condition = "false";

        if (response.data.status === true && response.data.code === 200) {
          onAddCallBack(response);
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.message);
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getAllSkills = (paginate) => {
    SkillQuarterService.getAllSkills(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let data1 = { value: x.id, label: x.skill_id };
          data.push(data1);
        });
        setAllSkillData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getEmployee = (paginate) => {
    SkillQuarterService.getEmployee(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let data1 = {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name} (${x?.username})`,
          };
          data.push(data1);
        });
        setAllEmployee(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const isDataExists = (values) => {
    if (dataSkill.length === 0) {
      return
    }
    if (dataEmp.length === 0) {
      return
    }
    let skil = dataSkill?.value;
    let emp = dataEmp?.value;
    let data = {};
    data["skill_id"] = skil;
    data["employee_id"] = emp;
    data["skill_level"] = values.skill_level;
    data["previous_level"] = values.previous_level;
    data["required_level"] = values.required_level;
    data["quarter"] = values.quarter;
    data["status"] = values.status;
    if (condition == "false") {
      condition = "true";
      SkillQuarterService.isDataExists(data)
        .then((res) => {
          setIsSkillExist(res?.data?.dataList?.is_exist);
          setIsSkillExistMsg(res?.data?.message);
          if (res?.data?.dataList?.is_exist === false && values?.id) {
            updateSkills(values);
          }
          if (
            res?.data?.dataList?.is_exist === false &&
            values?.id === undefined
          ) {
            saveSkills(values);
          }
        })
        .catch((err) => console.log("Error=>", err));
    }
  };

  const AllCheck = (values) => {
    let skil = dataSkill?.value;
    let emp = dataEmp?.value;
    let error = false;
    if (edit) {
      if (editSkills?.skill_id !== skil) {
        error = true;
      }
      if (editSkills?.employee_id !== emp) {
        error = true;
      }
      if (editSkills?.previous_level !== values.previous_level) {
        error = true;
      }
      if (editSkills?.required_level !== values.required_level) {
        error = true;
      }
      if (editSkills?.quarter !== values.quarter) {
        error = true;
      }
      if (editSkills?.status !== values.status) {
        error = true;
      }
    }
    if (error === false && values?.id) {
      updateSkills(values);
    }
    if (error === true && values?.id) {
      isDataExists(values);
    }
    if (error === false && values?.id === undefined) {
      isDataExists(values);
    }
  };

  const SkillPush = (e) => {
    setDataSkill(e);
  };
  const UserPush = (e) => {
    setDataEmp(e);
  };

  const locationPush = (e) => {
    setDataLocation(e);
  };

  useEffect(() => {
    getAllSkills(false);
    getEmployee(false);
    getSubPersonal(0);
  }, []);

  useEffect(() => {
    if (editSkills) {
      console.log(editSkills)
      let datas = { value: editSkills?.skill_id, label: editSkills?.skill_Id };
      let dataEm = {
        value: editSkills?.employee_id,
        label: editSkills?.employee_Id,
      };
      setDataSkill(datas);
      setDataEmp(dataEm);
      setSkillData(editSkills);
      setDataLocation({ value: editSkills.location_id, label: editSkills.location_ID })
      Object.keys(editSkills).forEach((item) => {
        skillData[item] = editSkills[item];
      });
    }
  }, [editSkills]);

  const getSubPersonal = (id) => {
    SkillService.getSubPersonal(id)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let data1 = {
            value: x.id,
            label: `${x?.name} ${x?.description}`,
          };
          data.push(data1);
        });
        setLocationData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const submitHandler = () => {
    if (dataSkill.length === 0) {
      setSkillError(true);
    }
    if (dataEmp.length === 0) {
      setEmployeeError(true);
    }
  };

  return (
    <>
      <Formik
        initialValues={skillData}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          AllCheck(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Modal
            show={showForm}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            size="lg"
            className="modaldefaultclose modaldefaultclosecenter"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {edit === true ? "Edit" : "Add"} Quarterly Reports
              </Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="row justify-content-center">
                  <div className="col-md-11">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label>
                            Skill ID<span className="text-danger">*</span>
                          </label>
                          <Select
                            name="skill_id"
                            id="skill_ids"
                            options={allSkillData}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(e) => {
                              SkillPush(e);
                              setSkillError(false);
                            }}
                            value={dataSkill}
                          />
                          {skillError && (
                            <div className="small text-danger">
                              *Skill Id is required
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label>
                            Employee ID<span className="text-danger">*</span>
                          </label>
                          <Select
                            name="employee_id"
                            id="employee_ids"
                            options={allEmployee}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(e) => {
                              UserPush(e);
                              setEmployeeError(false);
                            }}
                            value={dataEmp}
                          />
                          {employeeError && (
                            <div className="small text-danger">
                              *Employee Id is required
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label>
                            Actual Level<span className="text-danger">*</span>
                          </label>
                          <select
                            name="skill_level"
                            className="form-control newbgselect"
                            onChange={(e) => {
                              handleChange(e);
                              setSkillData({
                                ...skillData,
                                skill_level: e.target.value,
                              });
                            }}
                            value={values.skill_level}
                            onBlur={handleBlur}
                          >
                            <option value="">Select</option>
                            {/* <option value="No Skill">No Skill</option> */}
                            <option value="Beginner">Beginner</option>
                            <option value="Intermediate">Intermediate</option>
                            <option value="Advanced">Advanced</option>
                            <option value="Expert">Expert</option>
                          </select>
                          {touched.skill_level && errors.skill_level ? (
                            <div className="small text-danger">
                              {errors.skill_level}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label>
                            Current Level<span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control newbgselect"
                            name="previous_level"
                            onChange={(e) => {
                              handleChange(e);
                              setSkillData({
                                ...skillData,
                                previous_level: e.target.value,
                              });
                            }}
                            value={values.previous_level}
                            onBlur={handleBlur}
                          >
                            <option value="">Select</option>
                            {/* <option value="No Skill">No Skill</option> */}
                            <option value="Beginner">Beginner</option>
                            <option value="Intermediate">Intermediate</option>
                            <option value="Advanced">Advanced</option>
                            <option value="Expert">Expert</option>
                          </select>
                          {touched.previous_level && errors.previous_level ? (
                            <div className="small text-danger">
                              {errors.previous_level}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label>
                            Planned Level<span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control newbgselect"
                            name="required_level"
                            onChange={(e) => {
                              handleChange(e);
                              setSkillData({
                                ...skillData,
                                required_level: e.target.value,
                              });
                            }}
                            value={values.required_level}
                            onBlur={handleBlur}
                          >
                            <option value="">Select</option>
                            {/* <option value="No Skill">No Skill</option> */}
                            <option value="Beginner">Beginner</option>
                            <option value="Intermediate">Intermediate</option>
                            <option value="Advanced">Advanced</option>
                            <option value="Expert">Expert</option>
                          </select>
                          {touched.required_level && errors.required_level ? (
                            <div className="small text-danger">
                              {errors.required_level}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label>
                            Quarter<span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control newbgselect"
                            name="quarter"
                            onChange={(e) => {
                              handleChange(e);
                              setSkillData({
                                ...skillData,
                                quarter: e.target.value,
                              });
                            }}
                            value={values.quarter}
                            onBlur={handleBlur}
                          >
                            <option value="">Select</option>
                            <option value="Jan-Mar">January-March</option>
                            <option value="Apr-Jun">April-June</option>
                            <option value="Jul-Sep">July-September</option>
                            <option value="Oct-Dec">October-December</option>
                          </select>
                          {touched.quarter && errors.quarter ? (
                            <div className="small text-danger">
                              {errors.quarter}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* NEWWWWWWWWWWWWWWWWWWWWWWWW  */}

                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label>
                            Year
                          </label>
                          <input
                            type="number"
                            name="year"
                            className="form-control"
                            placeholder="Year"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.year}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group innergroup">
                          <label>
                            Location
                          </label>
                          <Select
                            name="location"
                            id="locations"
                            options={locationData}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(e) => {
                              locationPush(e);
                            }}
                            value={dataLocation}
                          />
                        </div>
                      </div>

                      {/* END NEW  */}

                      <div className="col-md-12">
                        <div className="form-group innergroup">
                          <label className="d-block mb-2">
                            Status<span className="text-danger">*</span>
                          </label>
                          <label
                            className="logCheck d-inline-block mr-4"
                            htmlFor="Planned"
                          >
                            Planned
                            <input
                              type="radio"
                              name="status"
                              id="Planned"
                              value={values.status}
                              onChange={() => {
                                setFieldValue("status", "Planned");
                              }}
                              checked={values.status === "Planned"}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label
                            className="logCheck d-inline-block mr-4"
                            htmlFor="Incomplete"
                          >
                            Incomplete
                            <input
                              type="radio"
                              name="status"
                              id="Incomplete"
                              value={values.status}
                              onChange={() => {
                                setFieldValue("status", "Incomplete");
                              }}
                              checked={values.status === "Incomplete"}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label
                            className="logCheck d-inline-block"
                            htmlFor="Complete"
                          >
                            Complete
                            <input
                              type="radio"
                              name="status"
                              id="Complete"
                              value={values.status}
                              onChange={() => {
                                setFieldValue("status", "Complete");
                              }}
                              checked={values.status === "Complete" || values.status === "complete"}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </Modal.Body>
              {isSkillExist && (
                <Alert variant="danger" className="mb-5 mt-2 text-center">
                  <i className="fa fa-exclamation-triangle"></i>
                  &nbsp;{isSkillExistMsg}
                </Alert>
              )}

              <Modal.Footer>
                {isLoading ? (
                  <Spinner
                    animation="border"
                    variant="primary"
                    as="span"
                    role="status"
                    aria-hidden="true"
                  ></Spinner>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary-inner bpi-main"
                      onClick={() => submitHandler()}
                    >
                      Submit
                    </button>
                  </>
                )}
              </Modal.Footer>
            </form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default AddSkillQuaterly;
