import React, { useEffect } from "react";
import { getAuthCode, getCurrentMember } from "./docu-sign-service";
import { useSelector, useDispatch } from "react-redux";
import { setDocuSignToDoCount } from "../redux/piDataStore";
import { useNavigate } from 'react-router-dom';

function DocuSign() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(()=>{
        const params = new URLSearchParams(window.location.search);
        let code = params.get('code')
        getAuthCodeData(code)
        
    },[])

    const getAuthCodeData = (code) => {
        getAuthCode(code).then((response)=>{
            let access_token = response?.data?.access_token
            getCurrentMembers(access_token)
            
        })
        .catch((error) => {
            window.location.replace("/dashboard")
          });
    }

    const getCurrentMembers = (access_token) => {
        
        getCurrentMember(access_token).then((response)=>{
            let url_params = response?.data?.Total
            dispatch(setDocuSignToDoCount(url_params));
            navigate('/dashboard')
            
        })
        .catch((error) => {
            window.location.replace("/dashboard")
          });
    }

    return (
        <>
        </>
    );
}

export default DocuSign;