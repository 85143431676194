import { createSlice } from "@reduxjs/toolkit";

export const piDataStore = createSlice({
  name: "counterPi",
  initialState: {
    dataAuditLog: [],
    dashboardData: [],
    count: 18,
    loader: false,
    userData: {},
    profileStatus: false,
    headerMenu: "false",
    OrgData: {},
    sidemenuStatus: false,
    finalUrlPermission: [],
    tracker: {},
    successFactor: [],
    linkAccessData: "",
    ideaRole: {},
    sortedModule: [],
    message: [],
    vehicleAssign: false,
  },
  reducers: {
    setAuditLog: (state, action) => {
      const auditData = action.payload;
      let storedata = [...state?.dataAuditLog, []];
      storedata.push(auditData);
      state.dataAuditLog = action.payload;
    },
    setDashboardValue: (state, action) => {
      state.dashboardData = action.payload;
    },
    setSortedModuleData: (state, action) => {
      state.sortedModule = action.payload;
    },
    setFontValue: (state, action) => {
      if (action.payload === "INCREMENT") {
        if (state.count <= 21) {
          return {
            count: state.count + 1,
          };
        }
      }
      if (action.payload === "DECREMENT") {
        if (state.count >= 16) {
          return {
            count: state.count - 1,
          };
        }
      }
      return state;
    },
    setDisplayLoader: (state, action) => {
      if (action.payload === "Display") {
        return {
          ...state,
          loader: true,
        };
      }
      if (action.payload === "Hide") {
        return {
          ...state,
          loader: false,
        };
      }
      return state;
    },
    setUserData: (state, action) => {
      return {
        ...state,
        userData: action.payload,
      };
    },
    setOrgData: (state, action) => {
      return {
        ...state,
        OrgData: action.payload,
      };
    },
    setCloseHeader: (state, action) => {
      state.headerMenu = action.payload;
    },
    setProfileStatus: (state, action) => {
      state.profileStatus = !state.profileStatus;
    },
    setSideMenuStatus: (state, action) => {
      state.sidemenuStatus = !state.sidemenuStatus;
    },
    setCoursesData: (state, action) => {
      state.successFactor = action.payload;
    },
    setFinalUrlPermission: (state, action) => {
      let jsonData = {};
      action.payload.map((obj) => {
        jsonData[obj.code] = obj;
      });
      state.finalUrlPermission = jsonData;
    },
    setTracker: (state, action) => {
      state.tracker = action.payload;
    },
    setAccessLink: (state, action) => {
      state.linkAccessData = action.payload;
    },
    setIdeaRolePermission: (state, action) => {
      state.ideaRole = action.payload;
    },
    setVehicleAssign: (state, action) => {
      state.vehicleAssign = !state.vehicleAssign;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    setDocuSignToDoCount: (state, action) => {
      console.log({action});
      
      state.docu_sign_count = action.payload;
    },
  },
});
export const {
  setAuditLog,
  setDashboardValue,
  setFontValue,
  setDisplayLoader,
  setUserData,
  setCloseHeader,
  setOrgData,
  setProfileStatus,
  setSideMenuStatus,
  setFinalUrlPermission,
  setTracker,
  setCoursesData,
  setAccessLink,
  setIdeaRolePermission,
  setSortedModuleData,
  setMessage,
  setVehicleAssign,
  setDocuSignToDoCount
} = piDataStore.actions;
export const selectAudit = (state) => state.counterPi.dataAuditLog;
export const selectDashboardData = (state) => state.counterPi.dashboardData;
export const selectPixelData = (state) => state.counterPi.count;
export const selectLoader = (state) => state.counterPi.loader;
export const selectUserData = (state) => state.counterPi.userData;
export const selectHeaderData = (state) => state.counterPi.headerMenu;
export const selectOrgData = (state) => state.counterPi.OrgData;
export const selectProfileStatus = (state) => state.counterPi.profileStatus;
export const selectSideMenuStatus = (state) => state.counterPi.sidemenuStatus;
export const selectTrackerData = (state) => state.counterPi.tracker;
export const selectCoursesValueData = (state) => state.counterPi.successFactor;
export const selectLinkAccess = (state) => state.counterPi.linkAccessData;
export const selectFinalUrlPermission = (state) =>
  state.counterPi.finalUrlPermission;
export const selectIdeaRole = (state) => state.counterPi.ideaRole;
export const selectMesaageData = (state) => state.counterPi.message;
export const selectSortedModule = (state) => state.counterPi.sortedModule;
export const selectVehicleAssignData = (state) => state.counterPi.vehicleAssign;
export const selectDocuSignToDoCount = (state) => state.counterPi.docu_sign_count;

export default piDataStore.reducer;
