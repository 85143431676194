import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setDisplayLoader } from "../redux/piDataStore";
import { Tooltip } from "react-tippy";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Modal, Dropdown } from "react-bootstrap";
import {
  getExportData,
  getExportValue,
  getReimbDec,
} from "./claimReimbServices";
import { getUsersListDataNew } from "../../service/userService";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import moment from "moment/moment";

function ReimbursementDecReport() {
  const [pageCount, setpageCount] = useState(1);
  const [searchStr, setSearchStr] = useState("");
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortData, setSortData] = useState({
    colName: "id",
    sort_by: "id",
  });
  const [filterData, setFilterData] = useState({
    user_id: "",
    created_at: "",
    user_status: "",
  });
  const USER_TYPE = [
    {
      label: "Active",
      value: "Active",
    },
    {
      label: "InActive",
      value: "InActive",
    },
  ];
  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);
  const [declData, setDeclData] = useState([]);
  const [userDataList, setUserDataList] = useState([]);
  const dispatch = useDispatch();

  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      setSearchStr(e.target.value);
      getReimbursementDeclarations(
        e.target.value?.toLowerCase(),
        currentPage,
        pageSizeNo,
        sortData?.sort_by,
        filterData?.user_id === "" ? 0 : filterData?.user_id?.value,
        filterData?.created_at,
        filterData?.user_status === "" ? "" : filterData?.user_status?.value,
        true
      );
    }
  };

  const getUserList = () => {
    getUsersListDataNew(0).then((response) => {
      if (response?.status === 200) {
        let res = response?.data?.dataList?.result
          .filter((obj) => {
            if (obj?.username !== "admin" && obj?.username !== "super_pi") {
              return true;
            }
          })
          .map((obj) => {
            return {
              label: `${obj?.first_name} ${obj?.last_name} (${obj?.username})`,
              value: obj?.id,
            };
          });
        setUserDataList(res);
      }
    });
  };

  const closeButtonCallBack = () => {
    let closeBar = document.getElementById("searchbar");
    if (closeBar.value != "") {
      closeBar.value = "";
      let data = "";
      setSearchStr("");
    }
  };

  const mainDataFilter = () => {
    getReimbursementDeclarations(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData?.sort_by,
      filterData?.user_id === "" ? 0 : filterData?.user_id?.value,
      filterData?.created_at,
      filterData?.user_status === "" ? "" : filterData?.user_status?.value,
      true
    );
    filterclose();
  };

  useEffect(() => {
    getUserList(0);
    getReimbursementDeclarations("", 1, 10, "-id", "", "", "", true);
  }, []);

  useEffect(() => {
    getReimbursementDeclarations(
      "",
      1,
      pageSizeNo,
      "-id",
      filterData?.user_id === "" ? 0 : filterData?.user_id?.value,
      filterData?.created_at,
      filterData?.user_status === "" ? "" : filterData?.user_status?.value,
      true
    );
  }, [pageSizeNo]);

  const handleKey = (
    search,
    pageNo,
    pageSizeNo,
    sortBy,
    userId,
    created_at,
    user_type,
    paginate
  ) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      pageSizeNo +
      "&sort_by=" +
      sortBy +
      "&paginate=" +
      paginate;

    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (userId !== "" && userId !== undefined && userId !== null) {
      queryParm = queryParm + "&userId=" + userId;
    }
    if (created_at !== "" && created_at !== undefined && created_at !== null) {
      queryParm = queryParm + "&created_at=" + created_at;
    }
    if (user_type !== "" && user_type !== undefined && user_type !== null) {
      queryParm = queryParm + "&user_type=" + user_type;
    }

    return queryParm;
  };

  const getReimbursementDeclarations = (
    search,
    pageNo,
    pageSizeNo,
    sortBy,
    userId,
    created_at,
    user_type,
    paginate
  ) => {
    dispatch(setDisplayLoader("Display"));
    getReimbDec(
      handleKey(
        search,
        pageNo,
        pageSizeNo,
        sortBy,
        userId,
        created_at,
        user_type,
        paginate
      )
    ).then((response) => {
      if (response?.status === 200) {
        dispatch(setDisplayLoader("Hide"));
        setDeclData(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages)
      }
    });
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);
    getReimbursementDeclarations(
      searchStr,
      currentPage,
      pageSizeNo,
      sortData?.sort_by,
      filterData?.user_id === "" ? 0 : filterData?.user_id?.value,
      filterData?.created_at,
      filterData?.user_status === "" ? "" : filterData?.user_status?.value,
      true
    );
  };

  const exportGridData = (
    search,
    pageNo,
    pageSizeNo,
    sortBy,
    userId,
    created_at,
    user_type,
    paginate
  ) => {
    dispatch(setDisplayLoader("Display"));
    getExportValue(
      handleKey(search, pageNo, pageSizeNo, sortBy, userId, created_at, user_type, paginate)
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "ReimbursementDeclaration.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  return (
    <div class="row">
      <div class="col-md-12">
        <div class="content-wrapper-inner content-wrapper-inner2">
          <div class="innerheadsec">
            <div className="row">
              <div class="col-md-12">
                <h4 class="inner-page-title">
                  Reimbursement Declaration Report
                </h4>
              </div>
              <div class="col-md-12">
                <div class="border-top mb-3"></div>
              </div>
            </div>
            <div className="row">
              <div class="col-lg-4">
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip
                      className="bs-tooltip-end tooltip"
                      id="overlay-example"
                    >
                      <div class="tooltip-inner text-left">
                        Search on <strong>User</strong>
                      </div>
                    </Tooltip>
                  }
                >
                  <div class="input-group table-search">
                    <span class="input-group-text">
                      <img src="images/search.png" alt="" />
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      id="searchbar"
                      placeholder="Search on User"
                      onChange={(e) => {
                        handleSearch(e);
                        setCurrentPage(1);
                      }}
                    />
                    <span class="input-group-text border-0">
                      {searchStr !== "" ? (
                        <i
                          class="far fa-times-circle cursor-pointer"
                          onClick={() => {
                            closeButtonCallBack();
                          }}
                        ></i>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </OverlayTrigger>
              </div>
              <div class="col-lg-8">
                <div class="inline-spacing btn-betweenspaing">
                  <Dropdown show>
                    <Dropdown.Toggle
                      variant="primary-inner dropdownbtn"
                      id="dropdown-basic"
                    >
                      <img src="images/export.png" alt="" class="mr-2" /> Export
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#">
                        <button
                          style={{ border: 0, background: "none" }}
                          onClick={() => {
                            exportGridData(
                              searchStr,
                              currentPage,
                              pageSizeNo,
                              sortData?.sort_by,
                              filterData?.user_id === "" ? 0 : filterData?.user_id?.value,
                              filterData?.created_at,
                              filterData?.user_status === "" ? "" : filterData?.user_status?.value,
                              true
                            );
                          }}
                        >
                          <i className="far fa-file-excel"></i>Excel
                        </button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <button onClick={filteropen} class="btn btn-secondary-inner">
                    <img src="images/filter.png" alt="" class="mr-3" />
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="approval-bg no-bg border-0 purchaseinnertabs">
          <div class="table-responsive">
            <table class="table table-striped table-bordered tablecured">
              <thead>
                <tr>
                  <th>User</th>
                  <th>LTA</th>
                  <th>MEAL</th>
                  <th>FUEL</th>
                  <th>DRIVER</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {declData?.length > 0
                  ? declData?.map((each) => (
                      <tr>
                        <td>{each?.user_det}</td>
                        <td>
                          {each?.lta === true
                            ? "Taxable"
                            : each?.lta === false
                            ? "Non-Taxable"
                            : ""}
                        </td>
                        <td>
                          {each?.meal === true
                            ? "Taxable"
                            : each?.meal === false
                            ? "Non-Taxable"
                            : ""}
                        </td>
                        <td>
                          {each?.fuel === true
                            ? "Taxable"
                            : each?.fuel === false
                            ? "Non-Taxable"
                            : ""}
                        </td>
                        <td>
                          {each?.driver === true
                            ? "Taxable"
                            : each?.driver === false
                            ? "Non-Taxable"
                            : ""}
                        </td>
                        <td>
                          {moment.utc(each?.updated_at).format(
                            "DD-MM-YYYY, HH:mm:ss"
                          )}
                        </td>
                      </tr>
                    ))
                  : ""}
              </tbody>
            </table>
          </div>
        </div>
        {declData?.length == 0 ? (
          <tr>
            <td colSpan={12}> No Record Found</td>
          </tr>
        ) : (
          <div class="row">
            <div class="col-sm-5">
              <div class="sortinglist">
                Show
                <select
                  class="form-control main-pointer"
                  onChange={(e) => {
                    setPageSizeNo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value={10} label={10}>
                    10
                  </option>
                  <option value={20} label={20}>
                    20
                  </option>
                  <option value={30} label={30}>
                    30
                  </option>
                  <option value={100} label={100}>
                    100
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-7">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-left"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )}
      </div>
      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="form-group innergroup">
            <label>Select User</label>
            <Select
              options={userDataList}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                setFilterData((prev) => ({
                  ...prev,
                  user_id: e,
                }));
              }}
              value={filterData?.user_id}
            />
          </div>
          <div class="form-group innergroup">
            <label>Created At</label>
            <input
              type="date"
              class="form-control"
              name="from_date"
              onChange={(e) => {
                setFilterData((prev) => ({
                  ...prev,
                  created_at: e.target.value,
                }));
              }}
              value={filterData.created_at}
            />
          </div>
          <div class="form-group innergroup">
            <label>User Status</label>
            <Select
              options={USER_TYPE}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                setFilterData((prev) => ({
                  ...prev,
                  user_status: e,
                }));
              }}
              value={filterData?.user_status}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-outline-danger"
            onClick={() => {
              setFilterData((prev) => ({
                ...prev,
                user_id: "",
                user_status: "",
                created_at: "",
              }));
              setCurrentPage(1);
              filterclose();
              getReimbursementDeclarations(
                searchStr,
                1,
                10,
                "-id",
                "",
                "",
                "",
                true
              );
            }}
          >
            CLEAR
          </button>
          <button
            class="btn btn-primary-inner bpi-main"
            onClick={() => mainDataFilter()}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ReimbursementDecReport;
