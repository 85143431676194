import React, { useEffect, useState } from "react";
import { Modal, Tabs, Tab } from "react-bootstrap";
import moment from "moment";
import { selectUserData } from "../redux/piDataStore";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as TravelListingAPI from "../../service/travelService";

function ViewExpenTravInt(props) {
  const userDetails = useSelector(selectUserData);
  const [formData, setFormData] = useState({});
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  let ddd = "";
  let backclosefunc;

  const getOtherExpenseList = (id, user_id) => {
    TravelListingAPI.getOtherExpenseList(`id=${id}&user_id=${user_id}`)
      .then((response) => {
        if (response?.data?.dataList?.result.length > 0)
          setFormData(response?.data?.dataList?.result[0]);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  useEffect(() => {
    let qry = new URLSearchParams(window.location.search);
    let transaction_id = qry.get("id");
    let uid = qry.get("user_id");

    getOtherExpenseList(
      transaction_id,

      uid
    );
  }, []);

  const Print = () => {
    // backclosefunc = onViewClose
    let originalContents = document.body.innerHTML;
    ddd = document.getElementsByClassName("px-4 pb-2")[0];
    var printContent = document
      .querySelectorAll("img")
      .forEach((img) => img.remove());
    printContent = document.getElementById("printablediv").innerHTML;
    document.body.innerHTML = printContent;
    window.print();

    document.body.innerHTML = originalContents;
  };

  const Print2 = () => {
    // backclosefunc = onViewClose
    let originalContents = document.body.innerHTML;
    ddd = document.getElementsByClassName("px-4 pb-2")[0];
    var printContent = document
      .querySelectorAll("img")
      .forEach((img) => img.remove());

    // printContent = printContent
    // .getElementById("printContent")
    // .remove();

    printContent = document.getElementById("printablediv").innerHTML;
    document.body.innerHTML = printContent;
    window.print();

    document.body.innerHTML = originalContents;
  };

  window.onafterprint = () => {
    setTimeout(() => {
      document.getElementById("print-btn-1").onclick = Print;
      document.getElementById("back-btn").onclick = goBack;
      // window.location.reload()
    }, 2000);
  };
  const goBack = () => {
    // window.history.go(-1)
    window.location.href = "/other-listing";
    return false;
  };

  return (
    <>
      <div>
        <div class="row" id="printablediv">
          <div class="col-md-12">
            <div class="content-wrapper-inner content-wrapper-inner2">
              <div class="innerheadsec pb-0">
                <div className="row">
                  <div class="col-md-12">
                    <h3 class="policyhead">
                      {formData?.expense_type == "petty"
                        ? `${formData?.organisation_name}`
                        : `${formData?.user_organisation_name}`}
                      <br />
                      Request ID:{" "}
                      {/* {formData?.expense_type == "petty"
                        ? `${formData?.organisation_code}PE`
                        : `${formData?.user_organisation_code}OE`}
                      {formData?.id} */}
                      {formData?.request_id
                        ? formData?.request_id
                        : formData?.expense_type == "petty"
                        ? `${formData?.organisation_code}PE`
                        : `${formData?.user_organisation_code}OE` +
                          formData?.id}
                    </h3>
                  </div>
                </div>
                {/* </div> */}
                <div class="d-flex viewformaftersubmit">
                  <div class="col-md-3 ml-4">
                    <div class="form-group innergroup">
                      <label>User Code</label>
                      <p>{formData?.user_code}</p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Name</label>
                      <p>{formData?.user_name}</p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Vendor Code</label>
                      <p>{formData?.user_vendor_code}</p>
                    </div>
                  </div>
                </div>
                {/* <div class="col-md-3">
                <div class="form-group innergroup">
                  <label>Request Id</label>
                  <p>{formData?.id}</p>
                </div>
              </div> */}
                <div className="d-flex viewformaftersubmit">
                  <div class="col-md-3 ml-4">
                    <div class="form-group innergroup">
                      <label>Location</label>
                      <p>{formData?.user_location}</p>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Grade</label>
                      <p>{formData?.user_grade}</p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group innergroup">
                      <label>Applied On</label>
                      <p>
                        {moment
                          .parseZone(formData?.created_at)
                          .format("DD-MM-YYYY hh:mm A")}
                      </p>
                    </div>
                  </div>
                </div>

                {/* </div> */}
              </div>

              {state?.dontShowPrint ? (
                <div class="col-md-3 ml-2 viewformaftersubmit" id="printt">
                  <div class="form-group innergroup">
                  <b style={{ color: userDetails?.dark_mode === false && "#FFF" }}>
                    <div>Download Attachments</div></b>
                    <div class="d-flex">
                      {formData?.attachment?.map((item, idx) => (
                        <>
                          <br />
                          <span class="mr-3">
                            <i class="fas fa-file-download"></i>
                            <a
                              href={item?.attachment_path}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {item?.attachment_path
                                ?.toLowerCase()
                                ?.includes(".pdf") ? (
                                <img
                                  src={"/images/policy-icon/pdf.png"}
                                  alt="image"
                                  width="70px"
                                  height="70px"
                                />
                              ) : item?.attachment_path
                                  ?.toLowerCase()
                                  ?.includes(".xlsb") ||
                                item?.attachment_path
                                  ?.toLowerCase()
                                  ?.includes(".xlsx") ||
                                item?.attachment_path
                                  ?.toLowerCase()
                                  ?.includes(".xls") ||
                                item?.attachment_path
                                  ?.toLowerCase()
                                  ?.includes(".csv") ? (
                                <img
                                  src={"/images/policy-icon/xlsx.png"}
                                  alt="image"
                                  width="70px"
                                  height="70px"
                                />
                              ) : item?.attachment_path
                                  ?.toLowerCase()
                                  ?.includes(".doc") ||
                                item?.attachment_path
                                  ?.toLowerCase()
                                  ?.includes(".docs") ||
                                item?.attachment_path
                                  ?.toLowerCase()
                                  ?.includes(".docx") ||
                                item?.attachment_path
                                  ?.toLowerCase()
                                  ?.includes(".png") ||
                                item?.attachment_path
                                  ?.toLowerCase()
                                  ?.includes(".jpg") ||
                                item?.attachment_path
                                  ?.toLowerCase()
                                  ?.includes(".jpeg") ? (
                                <img
                                  src={"/images/policy-icon/docs.png"}
                                  alt="image"
                                  width="70px"
                                  height="70px"
                                />
                              ) : item?.attachment_path
                                  ?.toLowerCase()
                                  ?.includes(".msg") ? (
                                <img
                                  src={"/images/policy-icon/msg.png"}
                                  alt="image"
                                  width="70px"
                                  height="70px"
                                />
                              ) : (
                                ""
                              )}
                            </a>
                          </span>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div class="approval-bg no-bg border-0 purchaseinnertabs addmoretravleblank">
                <Tabs defaultActiveKey="Domestic" id="approvaltabs">
                  <Tab
                    eventKey="Domestic"
                    title={<React.Fragment>Other Expense</React.Fragment>}
                  >
                    <div class="table-responsive">
                      <table class="viewpoprinttab table table-striped table-bordered tablecured viewpoOther">
                        <thead>
                          <tr>
                            <th style={{ width: "50px" }}>S.No</th>
                            <th style={{ width: "100px" }}>GL Account</th>
                            {formData?.expense_type == "petty" && (
                              <th style={{ width: "70px" }}>Cost Center</th>
                            )}
                            {state?.dontShowPrint == true ? (
                              <th style={{ width: "70px" }}>
                                Travel By/Machine No.
                              </th>
                            ) : (
                              ""
                            )}
                            <th style={{ width: "70px" }}>Bill No.</th>
                            <th style={{ width: "70px" }}>Bill Date</th>
                            {formData?.expense_type
                              ?.toLowerCase()
                              ?.includes("petty") && (
                              <>
                                {state?.dontShowPrint == true ? (
                                  <th style={{ width: "62px" }}>
                                    For Month Of
                                  </th>
                                ) : (
                                  ""
                                )}
                                <th style={{ width: "100px" }}>Description</th>
                              </>
                            )}
                            <th style={{ width: "100px" }}>
                              Reason for Expense
                            </th>
                            {formData?.expense_data?.map(
                              (data, _idx) =>
                                _idx == 0 &&
                                data?.is_picked &&
                                data?.ssc_user_status && (
                                  <th style={{ width: "100px" }}>
                                    Reason for Deduction
                                  </th>
                                )
                            )}
                            <th style={{ width: "100px" }}>Bill Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formData?.expense_data?.length ? (
                            formData?.expense_data?.map((data, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                {/* <td>
                                <button class="btn btn-blanktd text-primary">
                                  <i class="far fa-edit"></i>
                                </button>
                                <button class="btn btn-blanktd text-danger ml-2">
                                  <i class="far fa-trash-alt text-danger"></i>
                                </button>
                              </td> */}
                                <td>
                                  {data?.gl_account}-{data?.gl_desc}
                                </td>
                                {formData?.expense_type == "petty" && (
                                  <td>
                                    {data?.cost_center_code}-
                                    {data?.cost_center_name}
                                  </td>
                                )}
                                {state?.dontShowPrint == true ? (
                                  <td>
                                    {data?.travel_by
                                      ? data?.travel_by
                                      : data?.machine_no}
                                  </td>
                                ) : (
                                  ""
                                )}
                                <td>{data?.bill_number}</td>
                                <td>
                                  {moment
                                    .parseZone(data?.bill_date)
                                    .format("DD-MM-YYYY")}
                                </td>
                                {formData?.expense_type
                                  ?.toLowerCase()
                                  ?.includes("petty") ? (
                                  <>
                                    {state?.dontShowPrint == true ? (
                                      <td>
                                        {console.log(
                                          data?.for_month_of,
                                          "petty"
                                        )}
                                        {moment
                                          .parseZone(data?.for_month_of)
                                          .format("MMMM")}
                                      </td>
                                    ) : (
                                      ""
                                    )}
                                    <td>{data?.bill_desc}</td>
                                  </>
                                ) : (
                                  <></>
                                )}

                                <td>{data?.remarks}</td>

                                {data?.is_picked && data?.ssc_user_status && (
                                  <td>{data?.reason_for_deduction}</td>
                                )}

                                <td>{data?.bill_amount?.toFixed(2)}</td>
                                {/* <td>
                                {moment(data?.created_at).format("DD-MM-YYYY")}
                              </td> */}
                                {/* <td>
                                <a href="#">
                                  <i class="fas fa-paperclip"></i> View Doc
                                </a>
                              </td> */}
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={12}> No Record Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <p
                          style={{
                            color: "#0195d4",
                            fontWeight: "bold",
                          }}
                        >
                          Approve Details
                        </p>
                        <div class="table-responsive ">
                          <table class="viewpoprinttab table table-striped table-bordered tablecured viewpo">
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Approver Name</th>
                                <th>Approver Level</th>
                                <th>Status</th>
                                <th>Approved Date</th>
                                <th>Remarks</th>
                              </tr>
                            </thead>
                            <tbody>
                              {formData?.approver_remark_data?.map(
                                (data, index) => (
                                  <>
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>
                                        {`${data?.approver_name} (${data?.approver_username})`}
                                      </td>
                                      <td>
                                        <span>{data?.approver_type}</span>
                                      </td>
                                      <td>
                                        {!data?.status
                                          ? "Pending"
                                          : data?.status == "A"
                                          ? "Approved"
                                          : data?.status == "R"
                                          ? "Rejected"
                                          : ""}
                                      </td>
                                      <td>
                                        {data?.status !== null &&
                                        data?.updated_at
                                          ? moment
                                              .parseZone(data?.updated_at)
                                              .format("DD-MM-YYYY hh:mm A")
                                          : "-"}
                                      </td>
                                      <td>{data?.remark}</td>
                                    </tr>
                                  </>
                                )
                              )}

                              {formData?.ssc_user_status && (
                                <tr>
                                  <td>
                                    {formData?.approver_remark_data?.length + 1}
                                  </td>
                                  <td>
                                    {`${formData?.is_picked_by_user_name} (${formData?.is_picked_by_user_code})`}
                                  </td>
                                  <td>
                                    <span>
                                      Approver{" "}
                                      {formData?.approver_remark_data?.length +
                                        1}
                                    </span>
                                  </td>
                                  <td>
                                    {formData?.ssc_user_status == "A"
                                      ? "Approved"
                                      : ""}
                                  </td>
                                  <td>
                                    {moment
                                      .parseZone(formData?.ssc_user_approved_at)
                                      ?.format("DD-MM-YYYY hh:mm A")}
                                  </td>
                                  <td>{/* {data?.remark} */}</td>
                                </tr>
                              )}

                              {formData?.ssc_manager_status && (
                                <tr>
                                  <td>
                                    {formData?.approver_remark_data?.length + 2}
                                  </td>
                                  <td>
                                    {`${formData?.ssc_manager_user_name} (${formData?.ssc_manager_user_code})`}
                                  </td>
                                  <td>
                                    <span>
                                      Approver{" "}
                                      {formData?.approver_remark_data?.length +
                                        2}
                                    </span>
                                  </td>
                                  <td>
                                    {formData?.ssc_user_status == "A"
                                      ? "Approved"
                                      : ""}
                                  </td>
                                  <td>
                                    {moment
                                      .parseZone(formData?.ssc_user_approved_at)
                                      ?.format("DD-MM-YYYY hh:mm A")}
                                  </td>
                                  <td>{/* {data?.remark} */}</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div class="pl-3 mb-3 border-bottom">
                      <div class=" float-right mr-5" style={{ color: userDetails?.dark_mode === false && "#FFF" }}>
                        Applied Amount ₹{" "}
                        {formData.total_bill_amount?.toFixed(2)}{" "}
                        &nbsp;&nbsp;&nbsp;
                        {formData.ssc_user_status == "A"
                          ? `Approved Amount ₹ ` +
                            formData.total_approved?.toFixed(2)
                          : ""}
                        {/* <div class="col-md-3 float-right">
                            
                            <div class="form-group innergroup">
                              <label>
                              <span class="text-danger">*</span>
                              </label>
                              
                              <input
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="Enter Applied Amount"
                                value={finalTotals.totalApplied}
                                
                              />
                            </div>
                          </div> */}
                        {/* <div class="col-md-3 float-right">
                            <div class="form-group innergroup ml-3">
                              <label>
                                Approved Amount <span class="text-danger">*</span>
                              </label>
                              <input
                                type="number"
                                disabled
                                class="form-control"
                                placeholder="Enter Approved Amount"
                                value={finalTotals.totalApproved}

                              />
                            </div>
                          </div> */}
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
            <br />
            <br />
            {/* {state?.flag == false && (
              <>
                <div class=" float-right">Signature</div>
              </>
            )} */}
            {!state?.dontShowPrint ? (
              <div class=" float-right">Signature</div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div class="px-4 pb-2">
          {/* <div class="row align-items-center">
                      <div class="col-md-6">
                        <h6 class="f-14 mt-2"></h6>
                      </div>
                      <div class="col-md-6 text-sm-right">
                        <button class="btn btnblanktd text-primary">
                          <i class="far fa-plus-square mr-2"></i> Add More
                        </button>
                      </div>
                    </div> */}

          <div class="mb-3">
            <div class="row">
              <div class="col-md-12">
                <div class="border-top my-3"></div>
              </div>

              <div class="col-md-12 mt-3 text-center">
                <button
                  id="back-btn"
                  class="btn btn-outline-secondary mr-2"
                  onClick={() =>
                    location.state
                      ? navigate(location.state.prev, {
                          state: {
                            subtypeid: location?.state?.subId,
                            subTypeName: "Other Expense",
                          },
                        })
                      : window.close()
                  }
                  // onClick={() => {
                  //   window.history.go(-1);
                  // }}
                >
                  Back
                </button>
                {!state?.dontShowPrint ? (
                  <button
                    class="btn btn-primary-inner bpi-main"
                    type="button"
                    id="print-btn-1"
                    onClick={() => Print()}
                  >
                    Print
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewExpenTravInt;
