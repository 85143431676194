import React from "react";
import { useState, useEffect } from "react";
import * as TravelListingAPI from "../../service/travelService";
import { ToastContainer, toast } from "react-toastify";
import * as moment from "moment";
import Select from "react-select";
import { selectUserData, setDisplayLoader,selectFinalUrlPermission } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { getAllUserDataNew } from "../../service/AttendanceService";
import { injectStyle } from "react-toastify/dist/inject-style";
if (typeof window !== "undefined") {
  injectStyle();
}
const TravelBackDateEntry = (props) => {
  const {
    edit,
    onViewClose,
    editValueData,
    editValue,
    setEditValue,
    handleCallBack,
    bhr,
  } = props;

  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);
  const [searchStr, setSearchStr] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [pageRecord, setpageRecord] = useState("");
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });
  const [cityDataTo, setCityDataTo] = useState([]);
  const [cityValue, setCityValue] = useState();
  const [cityValueTo, setCityValueTo] = useState();
  const [cityValueNew, setCityValueNew] = useState();
  const [cityValueToNew, setCityValueToNew] = useState();
  const [purposeValue, setPurposeValue] = useState([]);
  const [purposeValueNew, setPurposeValueNew] = useState([]);
  const [isExist, setIsExist] = useState(false);
  const [backDateData, setBackDateData] = useState();
  const [allEmployee, setAllEmployee] = useState([]);
  const [showHide, setShowHide] = useState(false);
  let condition = "false";
  const todayDate = new Date();
  const FromDate = moment(todayDate).format("YYYY-MM-DD");
  const ToDate = moment(todayDate).format("YYYY-MM-DD");
  const FinalDate = moment(todayDate).format("YYYY-MM-DD");
  const [formData, setFormData] = useState({
    user_id: "",
    from_date: FromDate,
    to_date: ToDate,
    opened_from: FinalDate,
  });
  const backDate = new Date();
  const [empIdData, setEmpIdData] = useState();
  var d = new Date();
  d.setMonth(d.getMonth() - 3);

  var module_d = new Date();
  module_d.setMonth(module_d.getMonth()-11);

  const [formValidation, setFormValidation] = useState({
    user_id: "",
    from_date: "",
    to_date: "",
    opened_from: "",
  });

  const handleValidate = () => {
    let isError = false;
    const formerr = { ...formValidation };
    if (!formData.user_id) {
      isError = true;
      formerr.user_id = "*Employee ID is required ";
      setFormValidation(formerr);
    }
    if (!formData.from_date) {
      isError = true;
      formerr.from_date = "From Date is required";
      setFormValidation(formerr);
    }
    if (!formData.to_date) {
      isError = true;
      formerr.to_date = "To Date is required";
      setFormValidation(formerr);
    }
    if (!formData.opened_from) {
      isError = true;
      formerr.opened_from = "*Open From is required";
      setFormValidation(formerr);
    }

    return isError;
  };

  const resetState = () => {
    setCityValue("");
    setPurposeValue("");
    setCityDataTo("");
    setCityValueTo("");
    setCityValueNew("");
    setCityValueToNew("");
    setPurposeValueNew("");
    setFormData({
      from_city_input: "",
      to_city_input: "",
      from_date: "",
      from_time: "",
      to_time: "",
      to_date: "",
      reason: "",
      purpose_of_travel_id: "",
    });
  };
  const getBackDateList = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id,
    status,
    from_date,
    to_date
  ) => {
    dispatch(setDisplayLoader("Display"));
    TravelListingAPI.getBackDateList(
      handleKey(
        search,
        page_no,
        page_size,
        sort_by,
        paginate,
        user_id,
        status,
        from_date,
        to_date
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setBackDateData(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
        setpageRecord(response?.data?.dataList?.paginated_data?.totalRecords);
      })
      .catch((error) => {
        dispatch(setDisplayLoader("Hide"));
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  const handleKey = (
    search,
    page_no,
    page_size,
    sort_by,
    paginate,
    user_id,
    status,
    from_date,
    to_date
  ) => {
    let queryParm =
      "page_no=" +
      page_no +
      "&page_size=" +
      page_size +
      "&paginate=" +
      paginate;
    if (sort_by !== "" && sort_by !== undefined && sort_by !== null) {
      queryParm = queryParm + "&sort_by=" + sort_by;
    }
    if (search !== "" && search !== undefined && search !== null) {
      queryParm = queryParm + "&search=" + search;
    }
    if (from_date !== "" && from_date !== undefined && from_date !== null) {
      queryParm = queryParm + "&from_date=" + from_date;
    }
    if (to_date !== "" && to_date !== undefined && to_date !== null) {
      queryParm = queryParm + "&to_date=" + to_date;
    }
    if (user_id !== "" && user_id !== undefined && user_id !== null) {
      queryParm = queryParm + "&user_id=" + user_id;
    }

    if (status !== "" && status !== undefined && status !== null) {
      queryParm = queryParm + "&status=" + status;
    }

    return queryParm;
  };

  const getUserList = () => {
    let flag = bhr === undefined ? false : true;
    let loc = true
    getAllUserDataNew(0, flag,loc).then((response) => {
      if (response?.status === 200) {
        let res = response?.data?.dataList?.result
          .filter((obj) => {
            if (obj?.username !== "admin" && obj?.username !== "super_pi") {
              return true;
            }
          })
          .map((obj) => {
            return {
              label: `${obj?.first_name} ${obj?.last_name} (${obj?.username})`,
              value: obj?.id,
            };
          });
        setAllEmployee(res);
      }
    });
  };

  const saveTravelBackDateInfo = async (formData) => {
    if (condition == "false") {
      condition = "true";
      setIsLoading(true);
      let dd = formData;
      dd.check_type = "travel";
      dd.is_active = true;
      // dd.user_id = userData?.id;
      await TravelListingAPI.saveTravelBackDateInfo(dd)
        .then((response) => {
          condition = "false";
          if (response?.data.code == 200) {
            resetState();
            setFormData({
              // user_id: "",
              // from_date: "",
              // to_date: "",
              // opened_from: "",
              user_id: "",
              from_date: FromDate,
              to_date: ToDate,
              opened_from: FinalDate,
            });
            // navigate("/travel-listing");
            toast.success("Record Created Successfully");
            setEmpIdData({});
            handleCallBack();
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };

  const updateTravelBackDateInfo = async (formData) => {
    if (condition == "false") {
      condition = "true";
      setIsLoading(true);
      let dd = formData;
      dd.is_active = true;
      delete dd.created_by_user_code;
      delete dd.created_by_user_name;
      delete dd.user_code;
      delete dd.user_name;
      delete dd.created_by_id;
      delete dd.created_at;
      // dd.check_type = "travel";
      // dd.user_id = userData?.id;

      await TravelListingAPI.updateTravelBackDateInfo(dd, formData?.id)
        .then((response) => {
          condition = "false";
          if (response?.data.code == 200) {
            resetState();
            setFormData({
              // user_id: "",
              // from_date: "",
              // to_date: "",
              // opened_from: "",
              user_id: "",
              from_date: FromDate,
              to_date: ToDate,
              opened_from: FinalDate,
            });
            setFormValidation({
              user_id: "",
            });
            setEditValue(false);
            // navigate("/travel-listing");
            toast.success("Record Update Successfully");
            setEmpIdData({});
            handleCallBack();
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error.response.data.message);
          console.log(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              "failed"
          );
        });
    }
  };
  const isDataExists = () => {
    let data = {};
    // data["user_id"] = userData?.id;
    data["from_date"] = formData?.from_date;
    data["to_date"] = formData?.to_date;

    if (condition == "false") {
      condition = "true";
      TravelListingAPI.isDataExists(data)
        .then((res) => {
          setIsExist(res?.data?.dataList?.is_exist);
          if (res?.data?.dataList?.is_exist === false) {
            handleValidateForm();
          }
        })
        .catch((err) => console.log("Error=>", err));
    }
  };
  const handleValidateForm = () => {
    if (handleValidate()) return false;
    if (editValue === false) {
      saveTravelBackDateInfo(formData);
    } else {
      updateTravelBackDateInfo(formData);
    }
  };
  const pushEmpId = (e, i) => {
    setEmpIdData(e);
    setFormData({ ...formData, user_id: e.value });
  };

  useEffect(() => {
    getBackDateList(
      searchStr,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      userData?.id
    );
    getUserList();
  }, []);

  useEffect(() => {
    if (editValue == true) {
      setFormData(editValueData);
      setShowHide(false);
      let data = {
        value: editValueData.id,
        label: `${editValueData.user_name} ${editValueData.user_code}`,
      };
      setEmpIdData(data);
      Object.keys(editValueData).forEach((item) => {
        formData[item] = editValueData[item];
      });
    }
  }, [editValue]);

  return (
    <>
      {/* <OnDuty data={travelInt} /> */}

      <div class="px-4 pb-2">
        <div class="row align-items-center">
          <div class="col-md-6 mt-5" style={{ float: "left" }}>
            {" "}
            <h5 class="inner-page-title"> Travel Back Dated Entry Exception</h5>
          </div>
          {/* <div class="col-md-6 text-sm-right">
            <button
              class="btn btnblanktd text-primary"
              onClick={addMoreTravelInfo}
            >
              <i class="far fa-plus-square mr-2"></i> Add More
            </button>
          </div> */}
        </div>
        <div class="bg-light px-3 pt-3 mb-3">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group innergroup">
                <label>
                  Employee Id<span class="text-danger">*</span>
                </label>
                <Select
                  name="user_id"
                  options={allEmployee}
                  isDisabled={editValue}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => {
                    pushEmpId(e);
                    setFormValidation({
                      ...formValidation,
                      user_id: "",
                    });
                  }}
                  value={empIdData}
                />

                {editValue === false && formValidation.user_id !== "" ? (
                  <div className="small text-danger">
                    {formValidation.user_id}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group innergroup">
                <label>
                  Period Open From<span class="text-danger">*</span>
                </label>
                <input
                  style={{ fontSize: "13px" }}
                  type="date"
                  // min={(finalUrlValue?.A || finalUrlValue?.E ) ? moment(module_d).format("YYYY-MM-DD"): moment(d).format("YYYY-MM-DD")}
                  min={
                    userData.groups.some((data) =>
                      data?.name.toLowerCase().includes("ma payroll")
                    )
                      ? ""
                      : moment(d).format("YYYY-MM-DD")
                  }
                 
                  max={moment(backDate).format("YYYY-MM-DD")}
                  class="form-control"
                  name="opened_from"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      opened_from: e.target.value,
                    });
                    setFormValidation({
                      ...formValidation,
                      opened_from: "",
                    });
                  }}
                  value={formData?.opened_from}
                />
                {editValue === false && formValidation.opened_from !== "" ? (
                  <div className="small text-danger">
                    {formValidation.opened_from}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group innergroup">
                <label>
                  From Date<span class="text-danger">*</span>
                </label>

                <input
                  style={{ fontSize: "13px" }}
                  type="date"
                  class="form-control"
                  name="from_date"
                  min={moment(backDate).format("YYYY-MM-DD")}
                  // max={moment(backDate).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      from_date: e.target.value,
                      to_date: "",
                    });
                    setFormValidation({
                      ...formValidation,
                      from_date: "",
                    });
                  }}
                  value={formData?.from_date}
                />
                {editValue === false && formValidation.from_date !== "" ? (
                  <div className="small text-danger">
                    {formValidation.from_date}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group innergroup">
                <label>
                  To Date<span class="text-danger">*</span>
                </label>
                <input
                  style={{ fontSize: "13px" }}
                  type="date"
                  class="form-control"
                  name="to_date"
                  min={moment(formData?.from_date).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      to_date: e.target.value,
                    });
                    setFormValidation({
                      ...formValidation,
                      to_date: "",
                    });
                  }}
                  value={formData?.to_date}
                />
                {editValue === false && formValidation.to_date !== "" ? (
                  <div className="small text-danger">
                    {formValidation.to_date}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="border-top my-3"></div>
          </div>
          {isLoading ? (
            <Spinner
              animation="border"
              variant="primary"
              as="span"
              role="status"
              aria-hidden="true"
            ></Spinner>
          ) : (
            <>
              <div class="col-md-12 text-right">
                {editValue === true && showHide === false ? (
                  <Link
                    // class="btn btn-primary-inner bpi-main"
                    type="submit"
                    onClick={() => {
                      setShowHide(true);
                      setFormData({
                        ...formData,
                        user_id: "",
                        from_date: FromDate,
                        to_date: ToDate,
                        opened_from: FinalDate,
                      });
                      setEmpIdData("");
                      setEditValue(false);
                    }}
                    style={{ marginLeft: "10px" }}
                  >
                    <i class="far fa-check-circle"></i> Add New
                  </Link>
                ) : (
                  ""
                )}
              </div>
              <div class="col-md-12 text-center">
                {edit == true ? (
                  <button
                    class="btn btn-outline-secondary mr-2"
                    onClick={onViewClose}
                  >
                    Back
                  </button>
                ) : (
                  ""
                )}
                {editValue === true && showHide === false ? (
                  <button
                    class="btn btn-primary-inner bpi-main"
                    type="submit"
                    onClick={() => {
                      handleValidateForm();
                    }}
                  >
                    <i class="far fa-check-circle"></i> Update
                  </button>
                ) : (
                  <button
                    class="btn btn-primary-inner bpi-main"
                    type="submit"
                    onClick={() => {
                      handleValidateForm();
                    }}
                  >
                    <i class="far fa-check-circle"></i> Submit
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default TravelBackDateEntry;
