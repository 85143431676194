import React, { useEffect } from "react";
import { getConsentURL } from "./docu-sign-service";

function DocuSignHome() {

    useEffect(()=>{

        getConsentUrl()
        // let url = "https://account-d.docusign.com/oauth/auth?response_type=code&scope=impersonation signature content spring_read spring_write&client_id=d7860c85-8629-4a56-92e9-5b1b8e60c59f&redirect_uri=http://localhost:3000/docu-sign&&login_hint=notifications@piind.com"
        // window.location.replace(url);
        // const params = new URLSearchParams(window.location.search);
        // console.log(params.get('code'), 'code');
        
    },[])

    const getConsentUrl = () => {
        getConsentURL().then((response)=>{
            let url = response?.data
            
            window.location.replace(url);
        })
        .catch((error) => {
            window.location.replace("/dashboard")
          });
    }

    return (
        <>
        </>
    );
}

export default DocuSignHome;