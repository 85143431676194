import React, { useState, useEffect } from "react";
import * as MyProfileService from "../../service/profile";
import {
  selectUserData,
  setCoursesData,
  setAccessLink,
  selectDocuSignToDoCount,
  setDocuSignToDoCount
} from "../redux/piDataStore";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Modal, Tabs, Tab } from "react-bootstrap";
import AddSuccessFactor from "./AddSuccessFactor";
import Slider from "react-slick";
import { MD5 } from "crypto-js";
import { getDocuSignToken, getCurrentMember } from "./docu-sign-service";

let counter = 0;

const SuccessFactor = (props) => {
  const {
    assignment,
    approvalsettings,
    setTabStatus,
    tabStatus,
    grcData,
    rightsOfTab,
    servicesFact,
    successfact,
    todoComFact,
    todoCapexFact,
    todoGRCFact,
    urlValue,
    docuSignAccessFlag,
    docuSignAccessUrl
  } = props;
  const finalUser = useSelector(selectUserData);
  const docu_sign_count = useSelector(selectDocuSignToDoCount);
  

  const [successFactor, setSuccessFactor] = useState([]);
  const [itsmTodoList, setItsmTodoList] = useState([]);
  const [itsmTodoCount, setItsmTodoCount] = useState([]);
  const [comTodoList, setComItsmTodoList] = useState([]);
  const [capexTodoList, setCapexTodoList] = useState([]);
  const [grcTodoList, setGrcTodoList] = useState({});
  const [priceSchemeApprovalList, setPriceSchemeApprovalList] = useState([]);
  const [approvalsCount, setApprovalsCount] = useState();
  const [approvalsCount1, setApprovalsCount1] = useState();
  const [linkAccessData, setLinkAccessData] = useState();
  const [successFactorCountState, setSuccessFactorCountState] = useState([]);
  const [countValue, setCountValue] = useState();
  const [myData, setMyData] = useState([]);
  const [showFrom, setShowFrom] = useState(false);
  const [countData, setCountData] = useState();
  const [prodDataList, setProdDataList] = useState([]);
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);
  const [dataFirstDev, setDataFirstDev] = useState();
  const [valueAssignment, setValueAssignment] = useState();
  const [runOnce, setRunOnce] = useState(false);
  const [userGroup, setuserGroup] = useState({
    _userGroup: "",
  });
  const [unassignedTask, setUnassignedTask] = useState(0);
  const [assignedTask, setAssignedTask] = useState(0);
  const [docuSignTodoFlag, setDocuSignTodoFlag] = useState(false);
  const [docuSignConsentUrlFlag, setDocuSignConsentUrlFlag] = useState(false);
  const [taskItemModal, setTaskItemModal] = useState(false);
  const [taskItems, setTaskItems] = useState([]);
  const [taskName, setTaskName] = useState("");
  const [allTasks, setAllTasks] = useState([]);

  const userId = userData?.username;
  let urlLink = process.env.REACT_APP_PRODLINK_URL;
  let hmsUrlLink = process.env.REACT_APP_HMS_PROD_URL;

  useEffect(() => {
    getLinksAccess(userData?.username);
    getProdTodo(userData?.username);
    getPriceSchemeApprovalList(userData?.username);
    let interval = setInterval(() => {
      getProdTodo(userData?.username);
      setRunOnce(false);
    }, 1000 * 60 * 1);

    let interval2 = setInterval(() => {
      getPriceSchemeApprovalList(userData?.username);
    }, 1000 * 60 * 2);

    return () => {
      interval && clearInterval(interval);
      interval2 && clearInterval(interval2);
    };
  }, [userData]);

  const grcRedirection = (user) => {
    let urlVal =
      "https://grc.piind.in:44300/sap/bc/ui2/nwbc/?sap-client=900&sap-language=EN&sap-nwbc-node=root&sap-theme=sap_corbu";
    localStorage.setItem(
      "currsso",
      btoa(atob(btoa("GRC@@" + urlVal)) + "@@" + user)
    );
    window.open("/ssorequest", "_blank");
  };

  const saveSuccessFactorTodo = async (value) => {
    MyProfileService.saveSuccessFactorTodo(value)
      .then((response) => {
        let data = response?.data?.dataList?.result?.d?.results;
        setSuccessFactor(data);
        assignmentssuccessFToDoDetailsByCategory(data);
        dispatch(setCoursesData(data));
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  // useEffect(() => {
  //   if (todoComFact) {
  //     getComplianceTodo(userData?.username, "Owner");
  //   }
  //   let interval = setInterval(() => {
  //     if (todoComFact) {
  //       getComplianceTodo(userData?.username, "Owner");
  //     }
  //   }, 1000 * 60 * 1);
  //   return () => {
  //     interval && clearInterval(interval);
  //   };
  // }, [todoComFact]);

  const saveItsmLinklist = async (value) => {
    await MyProfileService.saveItsmLinklist(value)
      .then((response) => {
        let data1 = response?.data?.dataList?.result;
        if (data1 == "ERROR") {
          <></>;
        } else {
          if (data1?.length > 0) {
            setItsmTodoList(data1);
          }
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getComplianceTodo = async (user_id, value) => {
    await MyProfileService.getComplianceTodo(user_id, value)
      .then((response) => {
        let data1 = response?.data?.dataList?.data;
        if (data1.length > 0) {
          data1.map((obj, i) => (obj.sub_type_name = `Compliance${i}`));
          setComItsmTodoList(data1);
        }
        data1?.map((value, idx) => {
          setCountData(value.compliance_count);
        });
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getCapexTodo = async (user_id, flg) => {
    await MyProfileService.getCapexTodo(user_id)
      .then((response) => {
        if (!flg) {
          let data1 = response?.data?.dataList;
          data1.type = "Capex";
          setCapexTodoList(data1);
        } else {
          setTimeout(() => {
            let data2 = response?.data?.dataList;
            window.open(data2.url, "_blank");
          }, 100);
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  // const getGRCTodoList = async (user_id) => {
  //   await MyProfileService.getGRCTodoList(user_id)
  //     .then((response) => {
  //       let data1 = response?.data?.dataList;
  //       if (data1?.grc_todo_count !== "" && data1?.grc_username !== "") {
  //         data1.sub_type_name = "GRC";
  //         setGrcTodoList(data1);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //           "failed"
  //       );
  //     });
  // };

  const getPriceSchemeApprovalList = async (user_id) => {
    await MyProfileService.getPriceSchemeApprovalList(user_id)
      .then((response) => {
        let arr = [];
        let valData = response.data.dataList?.data?.BusinessTransactions;
        for (let lkey in valData) {
          let pp = {
            sub_type_name: "",
            sub_type_id: "ext",
            approvals_count: "",
            exturl: "",
          };
          pp["sub_type_name"] = lkey;
          for (let urlkey in valData[lkey]) {
            pp["approvals_count"] = valData[lkey].count;
            pp["exturl"] = hmsUrlLink + valData[lkey].url;
          }
          arr.push(pp);
        }
        let valDatas = response.data.dataList?.data?.Team;
        for (let lkey in valDatas) {
          let ppp = {
            sub_type_name: "",
            sub_type_id: "ext",
            approvals_count: "",
            exturl: "",
          };
          ppp["sub_type_name"] = lkey;
          for (let urlkey in valDatas[lkey]) {
            ppp.approvals_count= valDatas[lkey].count;
            ppp.exturl = hmsUrlLink + valDatas[lkey].url;
          }
          arr.push(ppp);
        }
        let dataArr = arr.filter((ele) => ele.approvals_count !== 0);        
        setPriceSchemeApprovalList(dataArr);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };
  
  const getAllData = async () => {

    // if (linkAccessData?.capex === "Y") {
    //   await getCapexTodo(userData?.username, false);
    // }
    // if (linkAccessData?.grc === "Y") {
    //   await getGRCTodoList(userData?.username);
    // }
    // await getProdTodo(userData?.username);
  };

  useEffect(() => {
    if (linkAccessData) {
      getAllData();
    }
    if (todoComFact) {
      getComplianceTodo(userData?.username, "Owner");
      getComplianceTodo(userData?.username, "Approver");
    }
    if (counter === 0) {
      counter += 1;
      setInterval(() => {
        getLinksAccess(userData?.username);
        if (linkAccessData) {
          getAllData();
        }
        if (todoComFact) {
          getComplianceTodo(userData?.username, "Owner");
          getComplianceTodo(userData?.username, "Approver");
        }
      }, 1000 * 60 * 1);
    }
  }, [linkAccessData, todoComFact]);

  const getProdTodo = async (user_id) => {
    await MyProfileService.getProdTodo(user_id)
      .then((response) => {
        let arr = [];

        let valData = response.data.dataList.data.data.Team;

        for (let lkey in valData) {
          let pp = {
            sub_type_name: "",
            sub_type_id: "ext",
            approvals_count: "",
            exturl: "",
          };
          pp["sub_type_name"] = lkey;
          for (let urlkey in valData[lkey]) {
            pp["approvals_count"] = valData[lkey][urlkey];
            pp["exturl"] = urlLink + valData[lkey].url;
          }
          arr.push(pp);
        }
        let dataArr = arr.filter((ele) => ele.approvals_count !== 0);
        setProdDataList(dataArr);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const getLinksAccess = async (user_id) => {
    await MyProfileService.getLinksAccess(user_id)
      .then((response) => {
        let data1 = response?.data?.dataList;
        if (
          data1?.capex === "N" &&
          data1?.esop === "N" &&
          data1?.grc === "N" &&
          data1?.mdo === "N"
        ) {
          setDataFirstDev("");
        } else {
          setDataFirstDev([]);
        }
        setRunOnce(false);
        setLinkAccessData(data1);
        dispatch(setAccessLink(data1));
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const setCapexInBusinessTransaction = (value, bt_data) => {
    let countMyTeam = 0;
    let countMyBusiness = 0;
    let compCount = 0;
    comTodoList?.map((item) => {
      compCount = Number(item.compliance_count) + compCount;
      // setComplianceTodoData(item);
    });
    prodDataList?.map((item) => {
      countMyTeam = item.approvals_count + countMyTeam;
    });
    priceSchemeApprovalList?.map((item) => {
      countMyBusiness = item.approvals_count + countMyBusiness;
    });
    let data = {
      type_name: "Business Transactions",
      sub_type: bt_data,
    };
    let teamData = {
      type_name: "ESS",
      sub_type: prodDataList,
    };
    let sum = 0;
    let countSum = 0;
    let count = 0;
    let countEss = 0;

    let capexCount =
      capexTodoList.count == undefined ? 0 : Number(capexTodoList.count);
    let grcCount =
      grcTodoList.grc_todo_count == undefined
        ? 0
        : Number(grcTodoList.grc_todo_count);
    

    valueAssignment &&
      valueAssignment.map((val) => {
        if (val.type_name === "Business Transaction") {
          count = count + 1;
          let calc = val.total === undefined ? 0 : Number(val.total);
          let countCalc =
            Number(countData) == NaN || countData == undefined ? 0 : countData;
          sum =
            Number(calc) + Number(capexCount) + Number(grcCount) + compCount + Number(countMyBusiness);
          setTimeout(() => {
            setApprovalsCount(sum);
          }, 1000);
        }
      });

    assignment &&
      assignment?.map((val) => {
        if (val.type_name == "ESS" && value === "prod") {
          prodDataList?.map((item) => {
            if (item?.approvals_count > 0) {
              val.sub_type.push(item);
              countSum = val.total + countMyTeam;
            }
          });
          countEss = countEss + 1;
          let countDataEss = countSum !== 0 ? countSum : val.total;
          setApprovalsCount1(countDataEss);
        }
      });

    if (
      count == 0 &&
      data?.sub_type !== undefined &&
      data?.sub_type?.length !== 0 &&
      (Object.keys(capexTodoList)?.length !== 0 ||
        grcTodoList.length !== 0 ||
        comTodoList.length !== 0)
    ) {
      assignment?.push(data);
      let countCalc = Number(countData) !== NaN ? 0 : Number(countData);
      sum = capexCount + grcCount + compCount + Number(countMyBusiness);
      setApprovalsCount(sum);
    }

    if (countEss == 0 && value === "prod" && prodDataList?.length > 0) {
      prodDataList?.map((val, i) => {
        let sumApproval =
          val?.approvals_count == null ? 0 : val?.approval_count;
        countSum = val.approvals_count + countSum;
      });
      if (countSum > 0) {
        assignment.push(teamData);
      }
      setApprovalsCount1(countSum);
    }
  };

  useEffect(() => {
    if (servicesFact) {
      userData?.is_sf_user && saveItsmLinklist(userData?.username);
    }
  }, [servicesFact]);

  useEffect(() => {
    let sum = 0;
    itsmTodoList?.map((value) => {
      sum = Number(value?.Count) + Number(sum);
    });
    setItsmTodoCount(sum);
  }, [itsmTodoList]);

  useEffect(() => {
    if (successfact) {
      saveSuccessFactorTodo(userData?.username);
    }
  }, [successfact]);

  // useEffect(() => {
  //   if (linkAccessData?.capex === "Y") {
  //     getCapexTodo(userData?.username, false);
  //   }
  //   let interval = setInterval(() => {
  //     if (linkAccessData?.capex === "Y") {
  //       getCapexTodo(userData?.username, false);
  //     }
  //   }, 1000 * 60 * 1);
  //   return () => {
  //     interval && clearInterval(interval);
  //   };
  // }, [linkAccessData]);

  // useEffect(() => {
  //   if (linkAccessData?.grc === "Y") {
  //     getGRCTodoList(userData?.username);
  //   }
  //   let interval = setInterval(() => {
  //     if (linkAccessData?.grc === "Y") {
  //       getGRCTodoList(userData?.username);
  //     }
  //   }, 1000 * 60 * 1);
  //   return () => {
  //     interval && clearInterval(interval);
  //   };
  // }, [linkAccessData]);

  useEffect(() => {
    let dataArrayValue = JSON.parse(JSON.stringify(capexTodoList));
    let grc = JSON.parse(JSON.stringify(grcTodoList));
    let priceScheme = JSON.parse(JSON.stringify(priceSchemeApprovalList));
    let compliVal = JSON.parse(JSON.stringify(comTodoList));
    if (compliVal !== undefined) {
      let data = [];
      if (priceScheme?.length > 0) {
        priceScheme?.map((item) => {
          if(item?.approvals_count > 0){
            data.push(item);
          }          
        });
      }
      if (dataArrayValue?.count > 0) {
        data.push(...[dataArrayValue]);
      }
      if (grc?.grc_todo_count !== undefined) {
        data.push(grc);
      }
      if (compliVal?.length > 0) {
        data.push(...compliVal);
      }
      if (data?.length > 0) {
        setDataFirstDev(data);
      }
      collectApprovals(data);
    }
  }, [prodDataList]);

  const collectApprovals = (data) => {
    if (
      prodDataList?.length !== undefined &&
      (data?.length !== undefined || data === "") &&
      (capexTodoList?.count !== undefined || capexTodoList?.length === 0) &&
      !(
        prodDataList?.length === 0 &&
        data?.length === 0 &&
        capexTodoList?.length === 0
      ) &&
      runOnce === false
    ) {
      setRunOnce(true);
      if (prodDataList?.length > 0 && data?.length > 0) {
        setCapexInBusinessTransaction("prod", data);
      } else if (prodDataList?.length === 0 && data?.length > 0) {
        setCapexInBusinessTransaction("prod", data);
      } else if (prodDataList?.length > 0 && data?.length == 0) {
        setCapexInBusinessTransaction("prod", data);
      }
    } else {
      setCapexInBusinessTransaction("prod", data);
    }
  };

  useEffect(() => {
    if (dataFirstDev !== undefined) {
      let valueDataY = assignment?.map((value) => value);
      if (valueDataY[0]?.type_name === "Business Transaction") {
        let valueGg = JSON.parse(JSON.stringify(dataFirstDev));
        valueDataY[0]?.sub_type?.push(...valueGg);
        let cat = [];
        let finalData = [];
        for (let i = 0; i < valueDataY[0]?.sub_type.length; i++) {
          if (!cat.includes(valueDataY[0]?.sub_type[i]?.sub_type_name)) {
            finalData.push(valueDataY[0]?.sub_type[i]);
            cat.push(valueDataY[0]?.sub_type[i]?.sub_type_name);
          }
        }
        valueDataY[0].sub_type = finalData;
        setValueAssignment(valueDataY);
      }
    }
  }, [dataFirstDev]);

  const onClose = () => {
    setShowFrom(false);
  };

  const clickOnLink = (value) => {
    setMyData(value);
    setShowFrom(true);
  };

  const assignmentssuccessFToDoDetailsByCategory = (successResponse) => {
    // let html = '';
    let successFactorCount = 0;
    let mergeCategoryCount1417 = 0;
    let mergeCategoryloop1417 = "";
    let categoryId14Title = "";
    let categoriesArray = successResponse.map((item) => item.categoryId);
    let cat1417ExistsCount =
      categoriesArray.includes("14") && categoriesArray.includes("17") ? 2 : 1;
    let sfUrlDash = "https://performancemanager10.successfactors.com";
    let company = "PI";
    successResponse?.forEach((sucFaArr) => {
      let res = "";
      let categoryId = "";
      let successFacCount = 0;

      if (sucFaArr.todos.results[0]) {
        res = sucFaArr.todos.results[0];
        categoryId = res.categoryId;
      }
      let pmActstr;
      if (res.url && res.url !== "") {
        pmActstr = res.url + "&username=" + userId + "&password=SSO";
      } else {
        pmActstr =
          sfUrlDash +
          "/?company=" +
          company +
          "&username=" +
          userId +
          "&password=SSO";
      }

      if (categoryId == "12") {
        assignmentsCategoryTwelvePopup(sucFaArr, successFactorCount);
      }
      if (categoryId == "10") {
        assignmentsCategoryTenPopup(sucFaArr, successFactorCount);
      }
      if (["14", "17", "39"].includes(categoryId)) {
        try {
          assignmentsCategory1417Merge(
            sucFaArr,
            successFactorCount,
            mergeCategoryCount1417,
            mergeCategoryloop1417,
            categoryId14Title,
            cat1417ExistsCount
          );
        } catch {}
      }

      if (!["10", "14", "17", "12", "39"].includes(categoryId)) {
        let mainHeading = "";
        if (sucFaArr.todos.results) {
          let j = sucFaArr.todos.results;

          j.forEach((toDoArr) => {
            if (toDoArr.categoryId.includes(0) && toDoArr.status == 2) {
              if (toDoArr.entries.results) {
                toDoArr.entries.results.forEach((r1) => {
                  if (r1.status == 2) {
                    successFactorCount += 1;
                    successFacCount += 1;
                  }
                });
              }
            } else {
              if (toDoArr.status == 2) {
                successFactorCount += 1;
                successFacCount += 1;
              }
            }

            mainHeading = sucFaArr.categoryLabel;
          });
        }

        const fli = successFactorCountState;
        if (
          successFacCount > 0 &&
          !["5", "14", "17", "12", "39"].includes(categoryId)
        ) {
          if (categoryId == "3") {
            const li = [];
            li["url"] =
              sfUrlDash +
              "/sf/compforms?company=" +
              company +
              "&username=" +
              userId +
              "&password=SSO";
            li["label"] = mainHeading;
            li["count"] = successFacCount;
            fli.push(li);
            setSuccessFactorCountState(fli);
            dataTypeValueSum();
          } else if (categoryId == "15") {
            const li = {};
            li["url"] =
              sfUrlDash +
              "/sf/learning?company=" +
              company +
              "&username=" +
              userId +
              "&password=SSO";
            li["label"] = mainHeading;
            li["count"] = successFacCount;
            fli.push(li);
            setSuccessFactorCountState(fli);
            dataTypeValueSum();
          } else if (categoryId == "0") {
            const li = {};
            li["url"] = pmActstr;
            li["label"] = "Review Goals and Performance";
            li["count"] = successFacCount;
            fli.push(li);
            setSuccessFactorCountState(fli);
            dataTypeValueSum();
          } else {
            const li = {};
            li["url"] = pmActstr;
            li["label"] = mainHeading;
            li["count"] = successFacCount;
            fli.push(li);
            setSuccessFactorCountState(fli);
            dataTypeValueSum();
          }

          if (mergeCategoryCount1417 > 0) {
            const li = {};
            li["url"] = pmActstr;
            li["label"] = mainHeading;
            li["count"] = successFacCount;
            fli.push(li);
            setSuccessFactorCountState(fli);
            dataTypeValueSum();
          }
        }
      }
    });
  };

  const popLink1 = [];
  const assignmentsCategoryTwelvePopup = (sucFaArr, successFactorCount) => {
    if (sucFaArr.todos.results !== undefined) {
      const j = sucFaArr.todos.results;
      let successFacCount = 0;
      for (let i = 0; i < j.length; i++) {
        const toDoArr = j[i];

        if (toDoArr.status !== undefined && toDoArr.status == 2) {
          successFactorCount += 1;
          successFacCount += 1;
        }

        let pmActstr10 = "home";
        if (toDoArr.status == 2) {
          pmActstr10 = toDoArr.url + "&username=" + userId + "&password=SSO";
          const localLink = {
            url: pmActstr10,
            label: toDoArr.name,
            count: 0,
          };
          popLink1.push(localLink);
        }
      }

      const fli = successFactorCountState;
      let cc = fli.findIndex((eValue) => eValue.url == "popup");
      if (cc !== undefined && cc > 0) {
        const li = fli[cc];
        li["count"] = li["count"] + successFacCount;
        fli[cc](li);
      } else {
        const li = [];
        li["url"] = "popup";
        li["label"] = sucFaArr.categoryLabel;
        li["count"] = successFacCount;
        li["childLink"] = popLink1;
        fli.push(li);
      }
      setSuccessFactorCountState(fli);
      dataTypeValueSum();
    }
  };

  const dataTypeValueSum = () => {
    let sum = 0;
    successFactorCountState?.map((data) => {
      sum = data.count + sum;
    });
    setCountValue(sum);
  };

  const assignmentsCategoryTenPopup = (sucFaArr, successFactorCount) => {
    const popLink = [];
    if (sucFaArr.todos.results !== undefined) {
      const j = sucFaArr.todos.results;
      let successFacCount = 0;
      for (let i = 0; i < j.length; i++) {
        const toDoArr = j[i];

        if (toDoArr.status !== undefined && toDoArr.status == 2) {
          successFactorCount += 1;
          successFacCount += 1;
        }

        let pmActstr10 = "home";
        if (toDoArr.status == 2) {
          pmActstr10 = toDoArr.url + "&username=" + userId + "&password=SSO";
          const localLink = {
            url: pmActstr10,
            label: toDoArr.name,
            count: 0,
          };
          popLink.push(localLink);
        }
      }
      const fli = successFactorCountState;
      const li = [];
      li["url"] = "popup1";
      li["label"] = sucFaArr.categoryLabel;
      li["count"] = successFacCount;
      li["childLink"] = popLink;
      fli.push(li);
      setSuccessFactorCountState(fli);
      dataTypeValueSum();
    }
  };

  const popLink = [];
  const assignmentsCategory1417Merge = (
    sucFaArr,
    successFactorCount,
    mergeCategoryCount1417,
    mergeCategoryloop1417,
    categoryId14Title,
    cat1417ExistsCount
  ) => {
    let successFacCount = 0;
    let j = sucFaArr.todos.results;

    for (let i = 0; i < j.length; i++) {
      let toDoArr = j[i];
      if (toDoArr?.entries?.results) {
        for (let j = 0; j < toDoArr.entries.results.length; j++) {
          let r1 = toDoArr.entries.results[j];
          if (r1.status == 2) {
            successFactorCount += 1;
            successFacCount += 1;
          }
        }
      }

      mergeCategoryCount1417 += successFacCount;
      let loopArray14 = "";
      let pmActstr10 = "home";
      if (toDoArr.status == 2 || toDoArr.status == 3) {
        if (toDoArr?.entries?.results) {
          for (let j = 0; j < toDoArr.entries.results.length; j++) {
            let r1 = toDoArr.entries.results[j];
            if (r1.url && r1.url != "") {
              pmActstr10 = r1.url;
            }
            const localLink = {
              url: pmActstr10,
              label: r1.subjectFullName,
              count: 0,
            };
            popLink.push(localLink);
          }
        }
      }
      mergeCategoryloop1417 += loopArray14;

      const fli = successFactorCountState;
      let cc = fli.findIndex((eValue) => eValue.url === "popup2");
      categoryId14Title = "Requests Waiting for My Approval";
      if (cc !== undefined && cc > 0) {
        const li = fli[cc];
        li["count"] = li["count"] + mergeCategoryCount1417;
        fli[cc](li);
      } else {
        const li = [];
        li["url"] = "popup2";
        li["label"] = categoryId14Title;
        li["childLink"] = popLink;
        li["count"] = mergeCategoryCount1417;
        fli.push(li);
      }
      setSuccessFactorCountState(fli);
      dataTypeValueSum();
    }
  };
  // function capitalizeFirstLetter(string) {
  //   console.log(string.charAt(0).toUpperCase() + string.slice(1).toLowerCase());
  //   return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  // }

  function capitalizeFirstLetter(str) {
    if (str == "Travel Expense SSC" || str == "Other Expense SSC") {
      return str;
    } else if (str != "OTV/NONPO") {
      if (str?.length <= 3) {
        str.toUpperCase();
        return str;
      } else {
        var splitStr = str?.toLowerCase().split(" ");
        for (var i = 0; i < splitStr?.length; i++) {
          splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr?.join(" ");
      }
    } else {
      return str;
    }
  }

  useEffect(() => {
    finalUser.groups.every((data) => {
      if (data?.name.toLowerCase() === "ssc manager") {
        userGroup._userGroup = data?.name.toLowerCase();
        return false;
      }
      return true;
    });
  }, []);

  useEffect(() => {
    checkDocuSignToken()
  }, []);

  const checkDocuSignToken = () => {
    getDocuSignToken().then((response)=>{
      console.log({response});
      if(response?.data?.code === 200){
        let access_token = response?.data?.access_token
        getCurrentMemberToDo(access_token)
        setDocuSignTodoFlag(true)
      }
      if(response?.data?.code === 404){
        setDocuSignConsentUrlFlag(true)
      }
      
    })
  }

  const getCurrentMemberToDo = (access_token) => {
    getCurrentMember(access_token).then((response)=>{
      let wf_task = response?.data?.workflow_task?.Total
      let assigned_task = response?.data?.legal_counsil_task !== null ? response?.data?.legal_counsil_task?.assigned_task_count : 0
      let unassigned_task = response?.data?.legal_counsil_task !== null ? response?.data?.legal_counsil_task?.unassigned_task_count : 0
      dispatch(setDocuSignToDoCount(wf_task));
      setUnassignedTask(unassigned_task)
      setAssignedTask(assigned_task)
      setAllTasks(response?.data)

    })
  }
  

  return (
    <>
      {assignment?.length !== 0 ||
      successFactorCountState?.length > 0 ||
      itsmTodoList?.length > 0 ||
      docuSignTodoFlag || docuSignConsentUrlFlag ||
      priceSchemeApprovalList?.length > 0
       ? (
        <Tabs
          activeKey={tabStatus === "" && successFactorCountState.length > 0 ? "SUCCESSFACTOR" : tabStatus === "" ? assignment[0]?.type_name : tabStatus}
          // defaultActiveKey={assignment[0]?.type_name}
          id="approvaltabs"
          onSelect={(e) => setTabStatus(e)}
        >          
          {assignment.map((value) => {
            if (
              (approvalsCount1 > 0 && approvalsCount1 != undefined) ||
              value?.total > 0 ||
              approvalsCount > 0
            ) {
              return (
                <Tab
                  eventKey={value.type_name}
                  title={
                    <React.Fragment>
                      {value?.type_name === "ESS"
                        ? "Employee Self Service"
                        : value?.type_name}
                      <span className="text-danger">
                        {value?.type_name === "ESS"
                          ? approvalsCount1
                          : value?.type_name === "Cashbook" ||
                            value?.type_name === "Assignments" || value?.type_name === "CMS"
                          ? value?.total
                          : value?.type_name == "My Idea"
                          ? value?.total
                          : value?.type_name == "Complaint Management"
                          ? value?.total
                          : value?.type_name == "Business Transaction" &&
                            (approvalsCount == 0 ||
                              Number(approvalsCount) == "NaN" ||
                              approvalsCount == undefined)
                          ? value?.total
                          : approvalsCount}
                      </span>
                    </React.Fragment>
                  }
                >
                  
                  <Slider className="clientslider" {...approvalsettings}>
                    {value?.sub_type?.map((item) => (
                      <>
                        {item?.sub_type_name == "Skill Matrix" &&
                        item?.approvals_count > 0 ? (
                          <Link to="/skill">
                            <div className="aprlTxt">
                              <h5>
                                {item?.approvals_count + item?.reviews_count}
                              </h5>
                              <p>{item?.sub_type_name}</p>
                            </div>
                          </Link>
                        ) : item?.sub_type_name == "Other Expense SSC" &&
                          item?.approvals_count > 0 &&
                          userGroup?._userGroup == "ssc manager" ? (
                          <Link to="/otherexp-check?createFlag=true">
                            <div className="aprlTxt">
                              <h5>{item?.approvals_count}</h5>
                              <p>{item?.sub_type_name}</p>
                            </div>
                          </Link>
                        ) : item?.sub_type_name == "Travel Expense SSC" &&
                        item?.approvals_count > 0 &&
                        userGroup?._userGroup == "ssc manager" ? (
                        <Link to="/travel-approve-expense?createFlag=true">
                          <div className="aprlTxt">
                            <h5>{item?.approvals_count}</h5>
                            <p>{item?.sub_type_name}</p>
                          </div>
                        </Link>
                      ) : (item?.sub_type_name == "F&F Approval" || item?.sub_type_name == "Price Approval" || item?.sub_type_name == "Scheme Approval" || item?.sub_type_name == "CE Approval" || item?.sub_type_name == "Block Order Approval") &&
                          item?.approvals_count > 0 ? (
                          <Link to={`${item?.exturl}`} target="_blank">
                            <div className="aprlTxt">
                              <h5>{item?.approvals_count}</h5>
                              <p>{item?.sub_type_name}</p>
                            </div>
                          </Link>
                        ) : item?.approvals_count > 0 &&
                          item?.approvals_count != undefined ? (
                          <div>
                            {item?.sub_type_id !== "ext" ? (
                              <Link
                                state={{
                                  subtypeid: item?.sub_type_id,
                                  subTypeName: item?.sub_type_name,
                                  role: item?.role,
                                }}
                                to={
                                  item?.sub_type_name ===
                                  "My Idea Approval (RM)"
                                    ? "/myTeamIdea"
                                    : item?.sub_type_name === "Idea Lead"
                                    ? "/idealead"
                                    : item?.sub_type_name ===
                                      "Under Implementation"
                                    ? "/idea-implementation"
                                    : item?.sub_type_name ===
                                      "Actionable Complaint"
                                    ? "/complaint-approval"
                                    : `/common-approve/${item?.sub_type_id}`
                                }
                              >
                                <div className="aprlTxt">
                                  <h5>{item?.approvals_count}</h5>
                                  <p>
                                    {capitalizeFirstLetter(
                                      item?.sub_type_name === "Demand"
                                        ? "Cash Demand"
                                        : item?.sub_type_name === "OTV"
                                        ? "OTV/NONPO"
                                        : item?.sub_type_name === "Expense"
                                        ? "Cash Expense"
                                        : item?.sub_type_name === "Receipt"
                                        ? "Cash Receipt"
                                        : item?.sub_type_name?.toLowerCase() ===
                                          "invoice"
                                        ? "Vendor Invoice"
                                        : item?.sub_type_name
                                    )}
                                  </p>
                                </div>
                              </Link>
                            ) : (
                              <a
                                href={item?.exturl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <div className="aprlTxt">
                                  <h5>{item?.approvals_count}</h5>
                                  <p>{item?.sub_type_name}</p>
                                </div>
                              </a>
                            )}
                          </div>
                        ) : item?.status_code === 200 && item?.count !== 0 ? (
                          <div
                            onClick={() =>
                              getCapexTodo(userData?.username, true)
                            }
                          >
                            <div
                              className="aprlTxt"
                              style={{ cursor: "pointer" }}
                            >
                              <h5>{item?.count}</h5>
                              <p>Cigma Approval</p>
                            </div>
                          </div>
                        ) : item?.grc_todo_count !== 0 &&
                          item?.grc_username !== "" &&
                          item?.grc_todo_count !== undefined ? (
                          <div>
                            <a
                              href=""
                              target="_blank"
                              rel="noreferrer"
                              onClick={() => grcRedirection(item?.grc_username)}
                            >
                              <Link
                                // to={urlValue + "&username=" + item.grc_username}
                                target="_blank"
                              >
                                <div className="aprlTxt">
                                  <h5>{item?.grc_todo_count}</h5>
                                  <p>GRC Approval</p>
                                </div>
                              </Link>
                            </a>
                          </div>
                        ) : countData > 0 &&
                          item?.compliance_count !== 0 &&
                          item?.url !== undefined ? (
                          <div>
                            <Link to={item?.url} target="_blank">
                              <div
                                className="aprlTxt"
                                style={{ cursor: "pointer" }}
                              >
                                <h5>{item?.compliance_count}</h5>
                                <p>{item?.title}</p>
                              </div>
                            </Link>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ))}
                  </Slider>
                </Tab>
              );
            }
          })}
            {((docu_sign_count === 0 && assignedTask === 0 && unassignedTask === 0) || !docuSignAccessFlag) ? "" : (
            <Tab
              eventKey="DOCUSIGN"
              title={
                <>
                  {"CLM (DOCUSIGN)"}
                  <span className="text-danger">
                    
                  {docu_sign_count !== null && docu_sign_count !== 'null' && docu_sign_count !== undefined ? 
                  docu_sign_count + assignedTask + unassignedTask 
                  : "?"
                  }
                  </span>
                </>
              }
            >
              {docuSignTodoFlag ?
              <>
              <Slider className="clientslider" {...approvalsettings}>

              {docu_sign_count !== null && docu_sign_count !== 'null' && docu_sign_count !== undefined && (
              <div>
              
              <div className="aprlTxt"
               onClick={()=>{
                window.open(docuSignAccessUrl, "_blank");
                // setTaskItemModal(true)
                // setTaskName("Workflow Task")
                // setTaskItems(allTasks?.workflow_task?.Items)
              }}
               >   
              <h5>{docu_sign_count}</h5>
              <p>WF Task</p>
            </div>
            </div>
            )}

            {assignedTask !== 0 && (
            <div>
            <div className="aprlTxt"
            onClick={()=>{
              window.open(docuSignAccessUrl, "_blank");
              // setTaskItemModal(true)
              // setTaskName("Assigned Task")
              // setTaskItems(allTasks?.legal_counsil_task?.assigned_task_item)
            }}
            >     
              <h5>{assignedTask}</h5>
              <p>Assigned Task</p>
            </div>
            </div>
            )}

            {unassignedTask !== 0 && (
            <div>
            <div className="aprlTxt"
              onClick={()=>{
                window.open(docuSignAccessUrl, "_blank");
                // setTaskItemModal(true)
                // setTaskName("UnAssigned Task")
                // setTaskItems(allTasks?.legal_counsil_task?.unassigned_task_item)
              }}
            >               
              <h5>{unassignedTask}</h5>
              <p>Unassigned Task</p>
            </div>
            </div>
            )}

            </Slider>
            </>
            :
            <div>
              <Link
                  to="/docu-sign-home"
                >
              <div className="aprlTxt">   
              <h5>?</h5>
              <p>Consent to proceed with CLM (DocuSign)</p>
            </div>
            </Link>
            </div>
            }
            </Tab>
            )}

          {successFactorCountState.length > 0 ? (
            <Tab
              eventKey="SUCCESSFACTOR"
              title={
                <>
                  {"SUCCESSFACTOR"}
                  <span className="text-danger">
                    {successFactorCountState.length == 0 ? "" : countValue}
                  </span>
                </>
              }
            >
              {successFactorCountState.length > 0 ? (
                <>
                  <Slider className="clientslider" {...approvalsettings}>
                    {successFactorCountState.map(
                      (value) =>
                        value.count > 0 && (
                          <>
                            {value.url == "popup" ||
                            value.url == "popup1" ||
                            value.url == "popup2" ? (
                              <div>
                                <Link
                                  to=""
                                  onClick={() => {
                                    clickOnLink(value);
                                  }}
                                >
                                  <div className="aprlTxt">
                                    <h5>{value?.count}</h5>
                                    <p>{value?.label}</p>
                                  </div>
                                </Link>
                              </div>
                            ) : (
                              <div>
                                <Link to={value.url} target="">
                                  <div className="aprlTxt">
                                    <h5>{value?.count}</h5>
                                    <p>{value?.label}</p>
                                  </div>
                                </Link>
                              </div>
                            )}
                          </>
                        )
                    )}
                  </Slider>
                </>
              ) : (
                <p class="mt-2 f-15 text-muted d-flex flex-column justify-content-center h-100 text-center">
                  <strong>No Successfactor Approvals are available.</strong>
                </p>
              )}
              <AddSuccessFactor
                showFrom={showFrom}
                myData={myData}
                onClose={onClose}
              />
            </Tab>
          ) : (
            ""
          )}

          {itsmTodoList?.length > 0 ? (
            <Tab
              eventKey="SERVICES"
              title={
                <>
                  {"SERVICES"}
                  <span className="text-danger">
                    {itsmTodoList.length == 0 ? "" : itsmTodoCount}
                  </span>
                </>
              }
            >
              {itsmTodoList?.length > 0 ? (
                <>
                  <Slider className="clientslider" {...approvalsettings}>
                    {itsmTodoList?.map((data) => (
                      <div>
                        <Link to={data?.Url} target="_blank">
                          <div className="aprlTxt">
                            <h5>{data?.Count}</h5>
                            <p>{data?.Module}</p>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </Slider>
                </>
              ) : (
                <p class="mt-2 f-15 text-muted d-flex flex-column justify-content-center h-100 text-center">
                  <strong>No Services Approvals are available.</strong>
                </p>
              )}
            </Tab>
          ) : (
            ""
          )}

          {/* {comTodoList.length > 0 ? (
          <Tab
            eventKey="COMPLIANCE_TODO"
            title={
              <>
                {"COMPLIANCE_TODO"}
                <span className="text-danger">
                  {comTodoList.length == 0 ? "" : countData}
                </span>
              </>
            }
          >
            {comTodoList.length > 0 ? (
              <>
                <Slider className="clientslider" {...approvalsettings}>
                  {comTodoList?.map((comTdData) => (
                    <div>
                      <Link to={comTdData?.url} target="_blank">
                        <div className="aprlTxt">
                          <h5>{comTdData?.compliance_count}</h5>
                          <p>{comTdData?.title}</p>
                        </div>
                      </Link>
                    </div>
                  ))}
                </Slider>
              </>
            ) : (
              <p class="mt-2 f-15 text-muted d-flex flex-column justify-content-center h-100 text-center">
                <strong>No Services Approvals are available.</strong>
              </p>
            )}
          </Tab>
        ) : (
          ""
        )} */}

          {/* {capexTodoList.count > 0 ? (
          <Tab
            eventKey="CAPEX_TODO"
            title={
              <>
                {"CAPEX_TODO"}
                <span className="text-danger">
                  {capexTodoList.count == 0 ? "" : capexTodoList.count}
                </span>
              </>
            }
          >
            {capexTodoList.count > 0 ? (
              <>
                <Slider className="clientslider" {...approvalsettings}>
                  <div>
                    <Link to={capexTodoList?.url} target="_blank">
                      <div className="aprlTxt">
                        <h5>{capexTodoList?.count}</h5>
                        <p>{comTdData?.title}</p>
                      </div>
                    </Link>
                  </div>
                </Slider>
              </>
            ) : (
              <p class="mt-2 f-15 text-muted d-flex flex-column justify-content-center h-100 text-center">
                <strong>No Services Approvals are available.</strong>
              </p>
            )}
          </Tab>
        ) : (
          ""
        )} */}

          {/* {grcTodoList.grc_todo_count > 0 ? (
          <Tab
            eventKey="GRC_TODO"
            title={
              <>
                {"GRC_TODO"}
                <span className="text-danger">
                  {grcTodoList.grc_todo_count == 0
                    ? ""
                    : grcTodoList.grc_todo_count}
                </span>
              </>
            }
          >
            {grcTodoList.grc_todo_count > 0 ? (
              <>
                <Slider className="clientslider" {...approvalsettings}>
                  <div>
                    <Link to={urlValue} target="_blank">
                      <div className="aprlTxt">
                        <h5>{grcTodoList.grc_todo_count}</h5>
                        <p>{grcTodoList?.grc_username}</p>
                      </div>
                    </Link>
                  </div>
                </Slider>
              </>
            ) : (
              <p class="mt-2 f-15 text-muted d-flex flex-column justify-content-center h-100 text-center">
                <strong>No Services Approvals are available.</strong>
              </p>
            )}
          </Tab>
        ) : (
          ""
        )} */}
        </Tabs>
      ) : (
        <div class="text-center">
          <img
            src="images/no-found.png"
            alt=""
            style={{ height: "140px", margin: "0 auto" }}
          />
          <p class="mt-2 f-15 text-muted">
            <strong>No Approval & Assignments available.</strong>
          </p>
        </div>
      )}
      <Modal
        show={taskItemModal}
        onHide={() => {
          setTaskItemModal(false);
          setTaskItems([]);
        }}
        size="lg"
        centered
        className="modaldefaultclose modaldefaultclosecenter"
      >
        <Modal.Header closeButton>
          <b>{taskName}</b>
        </Modal.Header>
        <Modal.Body>
        <div>
          {taskItems?.length > 0 ? (
            <>
              {/* <Slider className="clientslider" {...approvalsettings}> */}
                {taskItems?.map((data) => (
                  <div>
                    <Link
                      to={data?.WorkItemUrl}
                      target="_blank"
                      // onClick={() => {
                      //   grcRedirection(data?.grc_username);
                      // }}
                    >
                      <div className="aprlTxt">
                        <p style={{ fontSize: "20px" }}>{data?.Workflow?.Name}</p>
                      </div>
                    </Link>
                    <br />
                  </div>
                ))}
              {/* </Slider> */}
            </>
          ) : (
            <p class="mt-2 f-15 text-muted d-flex flex-column justify-content-center h-100 text-center">
              <strong>No {taskName} are available.</strong>
            </p>
          )}
        </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default SuccessFactor;
