import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as moment from "moment";
import * as purchaseOrderService from "../../service/purchase-order";
import * as searchPoService from "../../service/searchpo";
import { toast, ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import { selectFinalUrlPermission, setTracker } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";

if (typeof window !== "undefined") {
  injectStyle();
}

function ViewActivePo(props) {
  const dispatch = useDispatch();
  const finalUrlValue = useSelector(selectFinalUrlPermission);
  const { dataView, onViewClose, onCallBack, condition } = props;
  const [link, setLink] = useState(null);
  const [imgSize, setImgSize] = useState(false);
  const [error, setError] = useState(false);
  const [uploadAttachments, setUploadAttachments] = useState({
    attachment: {},
  });
  const [searchData, setSearchData] = useState({
    id: 0,
    is_approved: true,
    attachment: {},
    additional_comments: "",
  });

  let dataViews = dataView?.[0];

  useEffect(() => {
    setSearchData(dataViews);
  }, [dataViews]);

  const onProfileChange = (e, flag) => {
    const files = e.target.files[0];
    if (files.size < 4133660 || files.size === 4133660) {
      setLink(undefined);
      setImgSize(false);
      const fileData = new FormData();
      fileData.append("uploaded_file", files);
      purchaseOrderService.uploadAttachment(fileData).then((response) => {
        if (flag === "attachment") {
          setUploadAttachments({
            ...uploadAttachments,
            attachment: response.data,
          });
        }
        if(response?.data?.status === false){
          toast.error(response?.data?.message)
        }
      });
    } else {
      setImgSize(true);
    }
  };

  function validate(value) {
    if (searchData.additional_comments.length !== 0) {
      let data = {
        id: dataViews?.id,
        is_approved: `${value === "approve" ? true : false}`,
        attachment: uploadAttachments.attachment,
        additional_comments: searchData.additional_comments,
      };
      saveSearchPo(data);
      let obj = {
        permission_id: finalUrlValue?.A_P?.id,
        transaction_id: dataViews?.id
      }
      if(finalUrlValue?.A_P?.id !== null && finalUrlValue?.A_P?.id !== undefined){
        dispatch(setTracker(obj))
      }
    } else {
      setError(true);
    }
  }
  function validateDeclined() {
    if (searchData.additional_comments.length !== 0) {
      let data = {
        id: dataViews?.id,

        attachment: uploadAttachments.attachment,
        additional_comments: searchData.additional_comments,
      };
      onDeclinedSubmit(data);
      let obj = {
        permission_id: finalUrlValue?.E?.id,
        transaction_id: dataViews?.id
      }
      if(finalUrlValue?.E?.id !== null && finalUrlValue?.E?.id !== undefined){
        dispatch(setTracker(obj))
      }
      // alert("declined");
    } else {
      setError(true);
    }
  }

  const onDeclinedSubmit = (values) => {
    searchPoService
      .onDeclinedSubmit(values)
      .then((response) => {
        if (response.data.status === true && response.data.code === 200) {
          onCallBack();
          toast.success("Updated Successfully");
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

  const saveSearchPo = (values) => {
    searchPoService
      .saveSearchPo(values)
      .then((response) => {
        if (response.data.status === true && response.data.code === 200) {
          onCallBack();
          toast.success("Updated Successfully");
        }
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            "failed"
        );
      });
  };

   const changeData = (num) => {
     const result = Number(num).toFixed(2);
     return result;
   };

  return (
    <>
      <div className="row">
        {/* <div className="col-md-3">
          <ul className="policylistnames">
           
            <li>
              <Link to="/search-po">Search Order</Link>
            </li>
            <li class="active">
              <Link to="/active-po">Active PO Status</Link>
            </li>
            <li>
              <Link to="/approver-assignment">Approver Assignment</Link>
            </li>
            <li>
              <Link to="/releasegroup">Release Group</Link>
            </li>
            <li>
              <Link to="/releasecode">Release Code</Link>
            </li>
            <li>
              <Link to="/purchase-org">Purchase Organisation</Link>
            </li>
            <li>
              <Link to="/purchase-group">Purchase Group</Link>
            </li>
            <li>
              <Link to="/releasestrat">Release Strategy</Link>
            </li>
            <li>
              <Link to="/releasestrategy">Approval Mapping</Link>
            </li>
          </ul>
        </div> */}
        <div className="col-md-12">
          <div className="content-wrapper-inner content-wrapper-inner2">
            <div className="innerheadsec">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="policyhead">View PO: {dataViews?.po_no}</h3>
                </div>
              </div>
              <div className="row viewformaftersubmit">
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>PO No.</label>
                    <p>{dataViews?.po_no}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Created by</label>
                    <p>{dataViews?.created_by_user}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Purchasing Organization</label>
                    <p>
                      {dataViews?.purchase_org_code}-
                      {dataViews?.purchase_org_desc}
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Purchasing Group</label>
                    <p>
                      {" "}
                      {dataViews?.purchase_group_code}-
                      {dataViews?.purchase_group_desc}
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Release Group</label>
                    <p>
                      {" "}
                      {dataViews?.release_group_code}-
                      {dataViews?.release_group_desc}
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Release Strategy</label>
                    <p>
                      {dataViews?.release_stat_code}
                      {dataViews?.release_stat_desc === null
                        ? ""
                        : "-" + dataViews?.release_stat_desc}
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Budgeted Exchange Rate</label>
                    <p>82.00</p>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="form-group innergroup">
                    <label>Payment Term</label>
                    <p>{dataViews?.payment_term}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Total Gross Value</label>
                    <p>{changeData(dataViews?.total_price)}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>INCO Term</label>
                    <p>{dataViews?.inco_term}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Exchange Rate</label>
                    <p>{dataViews?.exchange_rate}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>Currency</label>
                    <p>{dataViews?.currency_code}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>PO Information Text</label>
                    <p>{dataViews?.info_text}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group innergroup">
                    <label>PO Category</label>
                    <p>{dataViews?.po_category}</p>
                  </div>
                </div>
                <div className="col-md-12 viewformaftersubmit">
                  <div className="form-group innergroup">
                    <label>Download Attachments</label>
                    {dataViews?.attachment?.length !== 0 ? (
                      <>
                        <p>
                          <span className="mr-3">
                            <a
                              style={{ color: "black" }}
                              target="_blank"
                              href={dataViews?.attachment}
                              rel="noreferrer"
                            >
                              <i class="fas fa-file-download"></i>
                              &nbsp;
                              {dataViews?.attachment?.slice(60, 200) + "..."}
                            </a>
                          </span>
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table table-striped table-bordered tablecured viewpo">
                <thead>
                  <tr>
                    <th>ITM.</th>
                    <th>Plant</th>
                    <th>Short Text</th>
                    <th>Qty.</th>
                    <th>Unit</th>
                    <th>Net Price</th>
                    <th>Net Value</th>
                    <th>Valuation</th>
                    <th>Budget Price (INR)</th>
                    <th>Landed Price (INR)</th>
                    <th>Var (%)</th>
                    <th>Tax Code</th>
                    <th>Taxes</th>
                    <th>Frieght/Custom</th>
                    <th>Last PO No.</th>
                    <th>Last PO Date</th>
                    <th>Last PO QTY.</th>
                    <th>Last PO Price</th>
                  </tr>
                </thead>
                <tbody>
                  {dataViews?.item?.length ? (
                    dataViews?.item?.map((data, index) => (
                      <>
                        <tr key={`po${index}`}>
                          <td>{data?.item_no}</td>
                          <td>{data?.plant}</td>
                          <td>
                            {data?.short_text}
                            <br />
                          </td>
                          <td>{data?.quantity}</td>
                          <td>{data?.unit}</td>
                          <td>{data?.net_price}</td>
                          <td>{data?.net_value}</td>
                          <td>{data?.valuation}</td>
                          <td>{data?.stdprice}</td>
                          <td>{data?.landing_price}</td>
                          <td>{data?.variance}</td>
                          <td>{data?.tax_code}</td>
                          <td>{data?.taxes}</td>
                          <td>{data?.freight}</td>
                          <td>{data?.last_po_number}</td>
                          <td>{data?.last_po_da}</td>
                          <td>{data?.last_po_quantity}</td>
                          <td>{data?.price_plant_sp}</td>
                        </tr>
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12}> No Record Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="row">
              <div className="col-md-8">
                <div className="table-responsive">
                  <table className="table table-striped table-bordered tablecured viewpo">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Approver Name</th>
                        <th>Approved Date</th>
                        <th>Approver Stage</th>
                        <th>Status</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataViews?.approval?.length ? (
                        dataViews?.approval?.map((data, index) => (
                          <>
                            <tr key={`po${index}`}>
                              <td>{index + 1}</td>
                              <td className="text-theme fw-bold">
                                {data?.approver_name} ({data?.approver_userid})
                              </td>
                              {data?.approver_date !== null && data?.status !== null ?
                              <td>
                                {moment
                                  .utc(data?.approver_date)
                                  .format("Do MMM YYYY")}
                              </td>
                              :
                              <td></td>
                              }
                              <td>
                                <span className="badge badge-secondary">
                                  {data?.approver_stage}
                                </span>
                              </td>
                              {data?.status === true ?
                              <td>
                                <span class="badge bad-status badge-success">
                                Approved
                                </span>
                              </td>
                              : data?.status === false ?
                              <td>
                                <span class="badge bad-status badge-danger">
                                Declined
                                </span>
                              </td>
                              :
                              <td>
                                <span class="badge bad-status badge-warning">
                                Pending
                                </span>
                              </td>
                              }
                              <td>{data?.remarks}</td>
                            </tr>
                          </>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={12}> No Record Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {condition?.view_page || condition?.is_editable ? (
              <>
                <div className="col-md-6">
                  <div className="form-group innergroup position-relative modaldefaultclose ">
                    <label>
                      Upload files <span className="text-danger"></span>
                    </label>
                    <input
                      type="text"
                      className="form-control bg-white"
                      value={uploadAttachments?.attachment?.original_name}
                      disabled
                      placeholder="(Image, PDF Format)"
                    />
                    <div className="upload-btn-wrapper up-loposition">
                      <button className="uploadBtn">Browse</button>
                      <input
                        type="file"
                        id="attachment"
                        name="attachment"
                        accept="image/png, image/jpeg"
                        onChange={(e) => onProfileChange(e, "attachment")}
                      />
                    </div>
                    {imgSize ? (
                      <div className="small text-danger">
                        File size should not exceed 4 MB
                      </div>
                    ) : null}
                    {uploadAttachments?.attachment?.original_name !==
                      undefined && (
                      <p>
                        Selected file{" "}
                        <span>
                          {" "}
                          {uploadAttachments?.attachment?.original_name}
                        </span>
                      </p>
                    )}
                    {link !== undefined && (
                      <p>Selected file {<span> {link}</span>}</p>
                    )}
                    <div className="d-flex"></div>
                  </div>
                </div>
                {dataViews?.sub_j_to_r == "X" ? (
                  <div className="col-md-12">
                    <div className="form-group innergroup">
                      <label>Comments</label>
                      <p>{dataViews?.additional_comments}</p>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="col-md-12">
                  <div className="form-group innergroup">
                    <label>
                      Add Comments<span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      name="additional_comments"
                      placeholder="Enter Comments here..."
                      maxLength={500}
                      onChange={(e) => {
                        setError(false);

                        setSearchData({
                          ...searchData,
                          additional_comments: e.target.value,
                        });
                      }}
                      value={searchData?.additional_comments}
                    ></textarea>
                    {error && (
                      <div className="small text-danger">
                        *Comments is required
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {condition?.view_page && (
              <>
                <div className="col-md-12 text-right pb-3">
                  <button
                    className="btn btn-primary-inner bpi-main"
                    type="submit"
                    onClick={() => validate("approve")}
                  >
                    Approve
                  </button>
                  <button
                    className="btn btn-outline-danger mr-3 ml-3"
                    type="button"
                    onClick={() => onViewClose()}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary-inner bpi-main"
                    type="submit"
                    onClick={() => validate("reject")}
                  >
                    Decline
                  </button>
                </div>
              </>
            )}

            {condition?.is_editable ? (
              <div className="col-md-12 text-right pb-3">
                <button
                  className="btn btn-primary-inner bpi-main"
                  type="button"
                  onClick={() => validateDeclined()}
                >
                  Submit
                </button>
                <button
                  className="btn btn-outline-danger mr-3 ml-3"
                  type="button"
                  onClick={() => onViewClose()}
                >
                  Cancel
                </button>
              </div>
            ) : condition?.view_page == false ? (
              <div className="col-md-12 text-right pb-3">
                <button
                  className="btn btn-outline-danger mr-3 ml-3"
                  type="button"
                  onClick={() => onViewClose()}
                >
                  Cancel
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewActivePo;
