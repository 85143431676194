import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Breadcrumb, Dropdown } from "react-bootstrap";
import * as ApproverServices from "../../service/approver";
import Select from "react-select";
import { selectUserData, setDisplayLoader } from "../redux/piDataStore";
import { useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast, ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import { Formik } from "formik";
import ReactPaginate from "react-paginate";
import AddApprover from "./AddApprover";
import PoBulkImport from "./PoBulkImport";
if (typeof window !== "undefined") {
  injectStyle();
}

function ApproverAssignment() {
  const dispatch = useDispatch();

  const userData = useSelector(selectUserData)

  const [show, filtersetShow] = useState(false);
  const filteropen = () => filtersetShow(true);
  const filterclose = () => filtersetShow(false);

  const [importshow, importsetShow] = useState(false);
  const importuser = () => importsetShow(true);
  const importclose = () => importsetShow(false);
  //-----------------------------------------------
  const [showForm, setShowForm] = useState(false);
  const [pageCount, setpageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [apprData, setApprData] = useState(null);
  const [pageSizeNo, setPageSizeNo] = useState(10);
  const [editMode, setEditMode] = useState(false);
  const [approverList, setApproverList] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [allGroupsData, setAllGroupsData] = useState([]);

  const [allStrat, setAllStrat] = useState([]);
  const [allStratData, setAllStratData] = useState([]);
  const [allCode, setAllCode] = useState([]);
  const [allCodeData, setAllCodeData] = useState([]);
  const [allPurOrg, setAllPurOrg] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const [allPurOrgData, setAllPurOrgData] = useState([]);
  const [allPurGroup, setAllPurGroup] = useState([]);
  const [allPurGroupData, setAllPurGroupData] = useState([]);
  const [allApproverId, setAllApproverId] = useState([]);
  const [allApproverData, setAllApproverData] = useState([]);
  const [sortData, setSortData] = useState({ colName: "id", sort_by: "-id" });

  const [dataFilter, setDataFilter] = useState({
    release_code_id: "",
    release_group_id: "",
    release_strat_id: "",
    purchase_group_id: "",
    purchase_org_id: "",
    approver_id: "",
  });

  const handleKey = (
    release_code_id,
    release_group_id,
    release_strat_id,
    purchase_group_id,
    purchase_org_id,
    approver_id,
    pageNo,
    page_size,
    sort_by,
    paginate,
    category_type
  ) => {
    let queryParm =
      "page_no=" +
      pageNo +
      "&page_size=" +
      page_size +
      "&sort_by=" +
      sort_by +
      "&paginate=" +
      paginate +
      "&category_type=" +
      category_type;
    if (
      release_code_id !== "" &&
      release_code_id !== undefined &&
      release_code_id !== null
    ) {
      queryParm = queryParm + "&release_code_id=" + release_code_id;
    }
    if (
      release_group_id !== "" &&
      release_group_id !== undefined &&
      release_group_id !== null
    ) {
      queryParm = queryParm + "&release_group_id=" + release_group_id;
    }
    if (
      release_strat_id !== "" &&
      release_strat_id !== undefined &&
      release_strat_id !== null
    ) {
      queryParm = queryParm + "&release_strat_id=" + release_strat_id;
    }
    if (
      purchase_group_id !== "" &&
      purchase_group_id !== undefined &&
      purchase_group_id !== null
    ) {
      queryParm = queryParm + "&purchase_group_id=" + purchase_group_id;
    }
    if (
      purchase_org_id !== "" &&
      purchase_org_id !== undefined &&
      purchase_org_id !== null
    ) {
      queryParm = queryParm + "&purchase_org_id=" + purchase_org_id;
    }
    if (
      approver_id !== "" &&
      approver_id !== undefined &&
      approver_id !== null
    ) {
      queryParm = queryParm + "&approver_id=" + approver_id;
    }
    return queryParm;
  };

  const getApprover = (
    release_code_id,
    release_group_id,
    release_strat_id,
    purchase_group_id,
    purchase_org_id,
    approver_id,
    pageNo,
    page_size,
    sort_by,
    paginate,
    category_type
  ) => {
    dispatch(setDisplayLoader("Display"));
    ApproverServices.getApprover(
      handleKey(
        release_code_id,
        release_group_id,
        release_strat_id,
        purchase_group_id,
        purchase_org_id,
        approver_id,
        pageNo,
        page_size,
        sort_by,
        paginate,
        category_type
      )
    )
      .then((response) => {
        dispatch(setDisplayLoader("Hide"));
        setApproverList(response?.data?.dataList?.result);
        setpageCount(response?.data?.dataList?.paginated_data?.totalPages);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getExportDetails = (
    release_code_id,
    release_group_id,
    release_strat_id,
    purchase_group_id,
    purchase_org_id,
    approver_id,
    pageNo,
    page_size,
    sort_by,
    paginate,
    category_type
  ) => {
    ApproverServices.getExportValue(
      handleKey(
        release_code_id,
        release_group_id,
        release_strat_id,
        purchase_group_id,
        purchase_org_id,
        approver_id,
        pageNo,
        page_size,
        sort_by,
        paginate,
        category_type
      )
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "ApproverAssign.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const addForm = () => {
    setShowForm(true);
    setEditMode(false);
    setApprData(null);
  };

  const closeForm = () => {
    setShowForm(false);
  };

  const editGrp = (data) => {
    setShowForm(true);
    setEditMode(true);
    setApprData(data);
  };
  const onAddCallBack = (response) => {
    setShowForm(false);
    getApprover(
      dataFilter.release_code_id,
      dataFilter.release_group_id,
      dataFilter.release_strat_id,
      dataFilter.purchase_group_id,
      dataFilter.purchase_org_id,
      dataFilter.approver_id,
      currentPage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "PO"
    );
  };

  const handlePageClick = (action) => {
    let activePage = action.selected + 1;
    setCurrentPage(activePage);

    getApprover(
      dataFilter.release_code_id,
      dataFilter.release_group_id,
      dataFilter.release_strat_id,
      dataFilter.purchase_group_id,
      dataFilter.purchase_org_id,
      dataFilter.approver_id,
      activePage,
      pageSizeNo,
      sortData.sort_by,
      true,
      "PO"
    );
  };

  const deleteApprover = (idx) => {
    ApproverServices.deleteApprover(idx)
      .then((res) => {
        if (res.data.status === true) {
          getApprover(
            dataFilter.release_code_id,
            dataFilter.release_group_id,
            dataFilter.release_strat_id,
            dataFilter.purchase_group_id,
            dataFilter.purchase_org_id,
            dataFilter.approver_id,
            currentPage,
            pageSizeNo,
            sortData.sort_by,
            true,
            "PO"
          );
          toast.success(res.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const submit = (id) => {
    confirmAlert({
      title: "Delete Approver Assignment",
      message: `Are you sure to delete?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteApprover(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const getAllGroups = (paginate) => {
    ApproverServices.getAllGroups(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let newData = {
            value: x.id,
            label: `${x?.code} - ${x?.desc}`,
          };
          data.push(newData);
        });
        setAllGroups(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getAllStrat = (paginate) => {
    ApproverServices.getAllStrat(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let newData = {
            value: x.id,
            label: `${x?.code} - ${x?.desc}`,
          };
          data.push(newData);
        });
        setAllStrat(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getAllCode = (paginate) => {
    ApproverServices.getAllCodes(paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let newData = {
            value: x.id,
            label: `${x?.code} - ${x?.desc}`,
          };
          data.push(newData);
        });
        setAllCode(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getPurOrg = (sort_by, paginate) => {
    ApproverServices.getPurOrg(sort_by, paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let newData = {
            value: x.id,
            label: `${x?.code} - ${x?.desc}`,
          };
          data.push(newData);
        });
        setAllPurOrg(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getPurGroup = (sort_by, paginate) => {
    ApproverServices.getPurGroup(sort_by, paginate)
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let newData = {
            value: x.id,
            label: `${x?.pgcode} - ${x?.pgdesc}`,
          };
          data.push(newData);
        });
        setAllPurGroup(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const getApproverId = () => {
    ApproverServices.getApproverId()
      .then((response) => {
        let data = [];
        response?.data?.dataList?.result.map((x) => {
          let newData = {
            value: x.id,
            label: `${x?.first_name} ${x?.last_name} (${x?.username})`,
          };
          data.push(newData);
        });
        setAllApproverId(data);
      })
      .catch((error) => {
        console.log(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          "failed"
        );
      });
  };

  const openFilter = () => {
    filteropen();
    getAllGroups(false);
    getApproverId();
    getAllStrat(false);
    getAllCode(false);
    getPurOrg("id", false);
    getPurGroup("id", false);
  };

  const mainDataFilter = (e) => {
    e.preventDefault();
    setFilterApplied(true);
    const relCode = dataFilter?.release_code_id;
    const relgrp = dataFilter?.release_group_id;
    const relstrt = dataFilter?.release_strat_id;
    const purGrpId = dataFilter?.purchase_group_id;
    const PurOrgId = dataFilter?.purchase_org_id;
    const ApprId = dataFilter?.approver_id;
    getApprover(
      relCode,
      relgrp,
      relstrt,
      purGrpId,
      PurOrgId,
      ApprId,
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      "PO"
    );
    filterclose();
  };

  const clearFilter = () => {
    setFilterApplied(false);
    let ClearData = JSON.parse(JSON.stringify(dataFilter));
    Object.entries(ClearData).map((type) => {
      if (
        type[1].constructor.name.toLowerCase() === "string" ||
        type[1].constructor.name.toLowerCase() === "array"
      ) {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      } else {
        if (type[1].length > 0) {
          ClearData[type[0]] = "";
        }
      }
    });
    setDataFilter({
      release_code_id: "",
      release_group_id: "",
      release_strat_id: "",
      purchase_group_id: "",
      purchase_org_id: "",
      approver_id: "",
    });
    setAllStratData([]);
    setAllGroupsData([]);
    setAllCodeData([]);
    setAllPurOrgData([]);
    setAllPurGroupData([]);
    setAllApproverData([]);
    setCurrentPage(1);
    filterclose();
    getApprover(
      "",
      "",
      "",
      "",
      "",
      "",
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      "PO"
    );
  };
  useEffect(() => {
    getApprover(
      "",
      "",
      "",
      "",
      "",
      "",
      1,
      pageSizeNo,
      sortData.sort_by,
      true,
      "PO"
    );
  }, [pageSizeNo]);
  return (
    <>
      {showForm ? (
        <>
          <AddApprover
            closeForm={closeForm}
            showForm={showForm}
            edit={editMode}
            editGrp={apprData}
            onAddCallBack={onAddCallBack}
            category="PO"
          />
        </>
      ) : (
        ""
      )}
      <div class="row">
        <ToastContainer autoClose={1000} />
        {/* <div class="col-md-3">
          <ul class="policylistnames">
            <li>
              <Link to="/search-po">Search Order</Link>
            </li>
            <li>
              <Link to="/active-po">Active PO Status</Link>
            </li>
            <li class="active">
              <Link to="/approver-assignment">Approver Assignment</Link>
            </li>
            <li>
              <Link to="/releasegroup">Release Group</Link>
            </li>
            <li>
              <Link to="/releasecode">Release Code</Link>
            </li>
            <li>
              <Link to="/purchase-org">Purchase Organisation</Link>
            </li>
            <li>
              <Link to="/purchase-group">Purchase Group</Link>
            </li>
            <li>
              <Link to="/releasestrat">Release Strategy</Link>
            </li>
            <li>
              <Link to="/releasestrategy">Approval Mapping</Link>
            </li>
          </ul>
        </div> */}
        <div class="col-md-12">
          <div class="content-wrapper-inner content-wrapper-inner2">
            <div class="innerheadsec">
              <div className="row">
                <div class="col-md-12">
                  <h4 class="inner-page-title">Approver Assignment</h4>
                </div>
                <div class="col-md-12">
                  <div class="border-top mb-3"></div>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-12">
                  <div class="inline-spacing btn-betweenspaing">
                    <Dropdown show>
                      <Dropdown.Toggle
                        variant="primary-inner dropdownbtn"
                        id="dropdown-basic"
                      >
                        <img src="images/export.png" alt="" class="mr-2" />{" "}
                        Export
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <button
                            type="button"
                            disabled={approverList.length !== 0 ? false : true}
                            style={{ border: 0, background: "none" }}
                            onClick={() =>
                              getExportDetails(
                                dataFilter.release_code_id,
                                dataFilter.release_group_id,
                                dataFilter.release_strat_id,
                                dataFilter.purchase_group_id,
                                dataFilter.purchase_org_id,
                                dataFilter.approver_id,
                                1,
                                pageSizeNo,
                                sortData.sort_by,
                                true,
                                "PO"
                              )
                            }
                          >
                            <i className="far fa-file-excel"></i>Excel
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button
                      onClick={() => openFilter()}
                      class="btn btn-secondary-inner"
                      style={{
                        backgroundColor: filterApplied && "yellow",
                        color: filterApplied && userData?.dark_mode === false && "#000"
                      }}
                    >
                      <img src="images/filter.png" alt="" class="mr-3" />
                      Filter
                    </button>
                    <button class="btn btn-danger" onClick={importuser}>
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Bulk Upload
                    </button>
                    <button
                      class="btn btn-primary-inner"
                      onClick={() => {
                        addForm();
                      }}
                    >
                      <img src="images/upload.png" alt="" class="mr-3" />
                      Add Approver
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped table-bordered tablecured">
                <thead>
                  <tr>
                    <th>Actions</th>
                    <th>Rel. Grp</th>
                    <th>Rel. Strategy</th>
                    <th>Rel. Code</th>
                    <th>Pur. Org</th>
                    <th>Pur. Grp</th>
                    <th>App. ID</th>
                    <th>App. Name</th>
                    <th>App. Status</th>
                  </tr>
                </thead>
                <tbody>
                  {approverList?.length ? (
                    approverList?.map((data, index) => (
                      <>
                        <tr key={`desg${index}`}>
                          <td>
                            <button
                              class="btn btn-blanktd text-primary ml-2 f-12"
                              type="button"
                              onClick={() => editGrp(data)}
                            >
                              <i class="far fa-edit"></i>
                            </button>
                            <button
                              class="btn btn-blanktd text-danger ml-2 f-12"
                              type="button"
                              onClick={() => {
                                submit(data?.id);
                              }}
                            >
                              <i class="far fa-trash-alt text-danger"></i>
                            </button>
                          </td>
                          <td>{data?.release_group_code}</td>
                          <td>{data?.release_strtgy_code}</td>
                          <td>{data?.release_code_code}</td>
                          <td>{data?.purchase_org_code}</td>
                          <td>{data?.purchase_group_code}</td>
                          <td>{data?.approver_emp_id}</td>
                          <td>{data?.approver_name}</td>                          
                          <td>{data?.approver_status ?
                                <span class="badge bad-status badge-success">Active</span> :
                                  <span class="badge bad-status badge-danger">Inactive</span>
                              }</td>
                        </tr>
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={12}> No Record Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {approverList?.length == 0 ? (
              ""
            ) : (
              <div className="row">
                <div className="col-sm-5">
                  <div className="sortinglist">
                    Show
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setPageSizeNo(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value={10} label={10}>
                        10
                      </option>
                      <option value={20} label={20}>
                        20
                      </option>
                      <option value={30} label={30}>
                        30
                      </option>
                      <option value={100} label={100}>
                        100
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-7">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-left"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={filterclose}
        className="filter-popup modaldefaultclose"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <div class="form-group innergroup">
              <label>
                Release Strategy <span class="text-danger"></span>
              </label>

              <Select
                name="release_strat_id"
                id="release_strat_id"
                options={allStrat}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => {
                  setAllStratData(e);
                  setDataFilter({
                    ...dataFilter,
                    release_strat_id: e.value,
                  });
                }}
                value={allStratData}
              />
            </div>
            <div class="form-group innergroup">
              <label>
                Release Code <span class="text-danger"></span>
              </label>

              <Select
                name="release_code_id"
                id="release_code_id"
                options={allCode}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => {
                  setAllCodeData(e);
                  setDataFilter({
                    ...dataFilter,
                    release_code_id: e.value,
                  });
                }}
                value={allCodeData}
              />
            </div>
            <div class="form-group innergroup">
              <label>
                Release Group <span class="text-danger"></span>
              </label>
              <Select
                name="release_group_id"
                id="release_group_id"
                options={allGroups}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => {
                  setAllGroupsData(e);
                  setDataFilter({
                    ...dataFilter,
                    release_group_id: e.value,
                  });
                }}
                value={allGroupsData}
              />
            </div>
            <div class="form-group innergroup">
              <label>
                Purchase Org. <span class="text-danger"></span>
              </label>

              <Select
                name="purchase_org_id"
                id="purchase_org_id"
                options={allPurOrg}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => {
                  setAllPurOrgData(e);
                  setDataFilter({
                    ...dataFilter,
                    purchase_org_id: e.value,
                  });
                }}
                value={allPurOrgData}
              />
            </div>
            <div class="form-group innergroup">
              <label>
                Purchase Group <span class="text-danger"></span>
              </label>

              <Select
                name="purchase_group_id"
                id="purchase_group_id"
                options={allPurGroup}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => {
                  setAllPurGroupData(e);
                  setDataFilter({
                    ...dataFilter,
                    purchase_group_id: e.value,
                  });
                }}
                value={allPurGroupData}
              />
            </div>
            <div class="form-group innergroup">
              <label>
                Approver Id <span class="text-danger"></span>
              </label>
              <Select
                name="approver_id"
                id="approver_id"
                options={allApproverId}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => {
                  setAllApproverData(e);
                  setDataFilter({
                    ...dataFilter,
                    approver_id: e.value,
                  });
                }}
                value={allApproverData}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              class="btn btn-outline-danger"
              type="button"
              onClick={() => {
                clearFilter();
              }}
            >
              Clear
            </button>
            <button
              class="btn btn-primary-inner bpi-main"
              type="submit"
              onClick={(e) => mainDataFilter(e)}
            >
              Apply
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      {importsetShow && (
        <PoBulkImport importshow={importshow} importclose={importclose} />
      )}
    </>
  );
}

export default ApproverAssignment;
